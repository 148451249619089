import React, { useState } from 'react';
import { authBackgroundColor } from '../../../constant/colors/background';
import { MdArrowForwardIos } from 'react-icons/md';
import clsx from 'clsx';
import { Card } from '@mui/material';
import BusinessProfile from '../components/businessProfile';
import ContactPerson from '../components/contactPerson';
import Contacts from '../components/contacts';
import BankDetails from '../components/bankDetails';
import { Pie } from 'react-chartjs-2';

const VendorSideMenuCard = ({
  title = 'Hello',
  index,
  activeMenu,
  handleMenuClick,
}: {
  title?: string;
  index: number;
  activeMenu: number;
  handleMenuClick: (id: number) => void;
}) => {
  return (
    <div
      onClick={() => handleMenuClick(index)}
      className={clsx(
        'cursor-pointer my-2 w-full px-3 py-2 flex justify-between rounded-[3px] font-semibold items-center',
        {
          'bg-[#663397] text-white': activeMenu === index,
          'border-[#663397] border text-black': activeMenu !== index,
        }
      )}
    >
      <div>{title}</div>
      <MdArrowForwardIos />
    </div>
  );
};

const DetailsCard = ({ title, data }: { title: string; data: string }) => {
  return (
    <div className='my-2 w-full px-3 py-2 rounded-[3px] font-semibold items-center bg-green-100 '>
      <div className='text-[10px] font-bold'>{title}</div>
      <div className='text-[9px] font-light '>{data}</div>
    </div>
  );
};

const VendorProfileMain = () => {
  const [sideCurrentActiveMenu, setSideCurrentActiveMenu] = useState(1);

  const data = {
    labels: ['Shopper', 'Commission'],
    datasets: [
      {
        label: 'Revenue',
        data: [9, 0.1],
        backgroundColor: ['rgba(102, 51, 151, 0.9)', 'rgba(231, 183, 15, 0.9)'],
        borderColor: ['rgba(255, 255, 255, 0.9)', 'rgba(255, 255, 255, 0.9)'],
      },
    ],
  };

  const vendorSideMenu = [
    { id: 1, name: 'Business Profile' },
    { id: 2, name: 'Contact Person' },
    { id: 3, name: 'Contacts' },
    { id: 4, name: 'Bank Details' },
  ];

  const handleSideMenuClick = (id: number) => {
    setSideCurrentActiveMenu(id);
  };

  return (
    <div className='flex gap-2 flex-wrap'>
      <div className=' w-full lg:w-[70%]'>
        <Card className='py-5 px-3'>
          <div className='flex gap-1'>
            <div className='w-[25%]'>
              <div>
                {vendorSideMenu.map((menu) => {
                  return (
                    <VendorSideMenuCard
                      title={menu.name}
                      index={menu.id}
                      activeMenu={sideCurrentActiveMenu}
                      handleMenuClick={handleSideMenuClick}
                    />
                  );
                })}
              </div>
              <div className=' p-2 my-2 bg-slate-200 rounded-[5px]'>
                <div className=' my-2 text-[12px] font-bold'>
                  Internal Details
                </div>

                <div>
                  <DetailsCard
                    title='Registration Date'
                    data='December 20, 2020 | 12:34:34'
                  />
                  <DetailsCard
                    title='Registration Type'
                    data='Self Application'
                  />
                  <DetailsCard title='On boarded by' data='Juma Justine' />

                  <DetailsCard title='Approved by' data='Neema Salum' />
                  <DetailsCard title='Commission' data='5% per sale' />
                  <DetailsCard title='Status' data='Premium' />
                </div>
              </div>
            </div>
            <div className='w-[70%] '>
              {sideCurrentActiveMenu === 1 && <BusinessProfile />}
              {sideCurrentActiveMenu === 2 && <ContactPerson />}
              {sideCurrentActiveMenu === 3 && <Contacts />}
              {sideCurrentActiveMenu === 4 && <BankDetails />}
            </div>
          </div>
        </Card>
      </div>
      <div className=' w-full lg:w-[25%]'>
        <div
          className={clsx(
            'flex justify-between py-5 px-3 my-2 text-white rounded-[5px]',
            authBackgroundColor
          )}
        >
          <div>
            <div className='text-[12px]'>Daily Sales</div>
            <div className='text-[20px]'>10,345</div>
          </div>
          <div className='h-[50px] border-l-0 border border-white' />
          <div>
            <div className='text-[12px]'>Daily Revenue</div>
            <div className='text-[20px]'>TZS 30,434,000</div>
          </div>
        </div>

        <div className='my-2'>
          <Card className='py-4 px-2'>
            <div>
              <div className='font-bold'>Revenue Distribution</div>
              <hr className='my-2' />

              <div>
                <Pie data={data} className='w-[90%]' />
              </div>

              <div className='flex justify-between my-2'>
                <div
                  className={clsx(
                    'p-3 rounded-[3px] min-w-[45%] ',
                    authBackgroundColor
                  )}
                >
                  <div className='text-[9px] text-white'>Vendor’s revenue</div>
                  <div className='text-[16px] text-bold text-white'>
                    1,521,700
                  </div>
                </div>

                <div className=' min-w-[45%]  p-3 rounded-[3px] bg-[#e7b70fe6]'>
                  <div className='text-[9px] text-white'>Commission</div>
                  <div className='text-[16px] text-bold text-white'>
                    1,521,700
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default VendorProfileMain;
