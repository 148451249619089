import clsx from 'clsx';
import { PiEnvelopeLight, PiPhone } from 'react-icons/pi';
import { settingsText, settingsText2 } from '../../constant/colors/text';
import { settingsIconsBackgroundInActive } from '../../constant/colors/background';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { FaUserCircle } from 'react-icons/fa';

type UserCardProps = {
  isActive?: boolean;
  firstName?: string;
  lastName?: string;
  phone?: string;
  id?: string;
  status?: string;
  gender?: string;
  createdAt?: string;
  handleSelect?: (id?: string, data?: {}) => void;
};

const UserCard = ({
  isActive,
  firstName,
  lastName,
  phone,
  status,
  id,
  createdAt,
  gender,
  handleSelect,
}: UserCardProps) => {
  return (
    <div
      onClick={() => {
        if (handleSelect) {
          const tempData = {
            fullName: `${firstName} ${lastName}`,
            phone,
            id,
            status,
            gender,
            createdAt,
          };

          handleSelect(id, tempData);
        }
      }}
      className={clsx(
        ' cursor-pointer p-4 flex justify-between border-b-[1px] rounded-[5px]',
        {
          [settingsIconsBackgroundInActive]: isActive,
        }
      )}
    >
      <div className='flex gap-4'>
        <div>
          <FaUserCircle size={43} />
        </div>
        <div>
          <div
            className={clsx('text-[15px]  font-semibold', {
              'text-white': isActive,
              'text-black': !isActive,
            })}
          >
            {`${firstName} ${lastName}`}
          </div>
          <div
            className={clsx(
              'flex items-center gap-1 text-[10px] cursor-pointer font-semibold',
              {
                'text-white': isActive,
                'text-black': !isActive,
              }
            )}
          >
            <PiPhone />
            {phone}
          </div>
        </div>
      </div>

      <div>
        <MdKeyboardArrowRight className='text-white' size={20} />
      </div>
    </div>
  );
};

export default UserCard;
