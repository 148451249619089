import clsx from 'clsx';
import React from 'react';
import { settingsIconsBackgroundInActive } from '../../constant/colors/background';
import { MdKeyboardArrowRight } from 'react-icons/md';

const CategoryCard = ({ isActive }: { isActive: boolean }) => {
  return (
    <div
      className={clsx(
        'flex items-center justify-between cursor-pointer rounded-[3px] px-5 py-2 my-3 border-b-[1px]',
        {
          [settingsIconsBackgroundInActive]: isActive,
        }
      )}
    >
      <div className='flex gap-3 items-center'>
        <img
          src='https://placehold.co/400'
          className='w-[50px] h-[50px] rounded-full'
        />
        <div>
          <p className='text-[20px]'>Shopping</p>
          <p className='text-[12px] text-[#565656]'>230 Vendors</p>
        </div>
      </div>
      <div>
        <MdKeyboardArrowRight className='text-white' size={20} />
      </div>
    </div>
  );
};

export default CategoryCard;
