import React, { HTMLProps, useState } from 'react';
import './basicTableWithSelection.css';

import {
  Column,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  SortingState,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import {
  authBackgroundColor,
  tableHeaderBackgroundColor,
} from '../../../constant/colors/background';

const BasicTableWithSelection = ({
  columns,
  row,
}: {
  columns: any;
  row?: any;
}) => {
  const rerender = React.useReducer(() => ({}), {})[1];

  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState('');

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data: row,
    columns,
    state: {
      rowSelection,
      pagination,
      sorting,
    },
    enableRowSelection: false,
    enableSorting: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    debugTable: false,
  });

  return (
    <div>
      <table className='w-[95%] rounded-3xl border-separate border-spacing-y-4 overflow-x-scroll'>
        <thead
          className={clsx(
            'h-[60px]  border-none font-[20px] text-white shadow-md text-left',
            tableHeaderBackgroundColor
          )}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  // <th
                  //   key={header.id}
                  //   colSpan={header.colSpan}
                  //   className='border-none capitalize cursor-pointer'
                  //   onClick={header.column.getToggleSortingHandler()}
                  // >
                  //   {header.isPlaceholder ? null : (
                  //     <>
                  //       {flexRender(
                  //         header.column.columnDef.header,
                  //         header.getContext()
                  //       )}
                  //     </>
                  //   )}
                  // </th>

                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        className={
                          header.column.getCanSort()
                            ? 'cursor-pointer select-none'
                            : ''
                        }
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                              ? 'Sort ascending'
                              : header.column.getNextSortingOrder() === 'desc'
                              ? 'Sort descending'
                              : 'Clear sort'
                            : undefined
                        }
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: ' 🔼',
                          desc: ' 🔽',
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className='text-[15px] text-[#5A5555] font-medium'>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr
                key={row.id}
                className='h-[60px] rounded-[3px] shadow-md mt-1 '
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className='pl-1'>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <span
        className={
          'flex gap-2 w-[400px] items-center text-white p-4 float-right'
        }
      >
        <button
          className={clsx(
            'px-5 py-2 rounded-lg font-bold',
            authBackgroundColor,
            {
              'opacity-75': !table.getCanPreviousPage(),
            }
          )}
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          Previous
        </button>
        <div className='text-black mx-3 font-black'>
          {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
        </div>
        <button
          className={clsx(
            'px-5 py-2 rounded-lg font-bold',
            authBackgroundColor,
            {
              'opacity-75': !table.getCanNextPage(),
            }
          )}
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          Next
        </button>
      </span>
    </div>
  );
};

export default BasicTableWithSelection;
