import React from 'react';
import { GridLoader } from 'react-spinners';

import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const GlobalSpinner = () => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  return isLoading ? (
    <div className='w-screen h-screen absolute  bg-[#cdcdcdb3] z-50 flex items-center justify-center'>
      <GridLoader color='#663397' size={20} className='opacity-100' />
    </div>
  ) : null;
};

export default GlobalSpinner;
