import React from 'react';
import { authBackgroundColor } from '../../constant/colors/background';
import clsx from 'clsx';

type Props = {
  children: React.ReactNode;
};

const AuthBase = (props: Props) => {
  return (
    <div
      className={clsx(
        'min-h-screen w-full  flex justify-center items-center',
        authBackgroundColor
      )}
    >
      {props.children}
    </div>
  );
};

export default AuthBase;
