import React, { useEffect, useState } from 'react';
import Card from '../../component/Card';
import { GrTransaction } from 'react-icons/gr';
import { Line } from 'react-chartjs-2';
import { BsFillPeopleFill } from 'react-icons/bs';
import SearchBar from '../../component/Searchbar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import apiRequest from '../../utils/apiRequest';
import toast from 'react-hot-toast';
import moment from 'moment';
import AccessControl from '../../component/AccessControl';
import { adminRole, supportRole } from '../../constant/roles';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../store/userSlice';

type DataGridRowType = {
  name: string;
  amount: string;
  time: string;
};

type DataGridType = {
  data: DataGridRowType[];
};

type TransactionDataType = {
  volume?: number;
  value?: number;
  time?: string[];
  amount?: number[];
  transaction?: {
    name?: string;
    amount?: string;
    time?: string;
  }[];
};

type UserDataType = {
  totalVendorsCount?: number;
  time?: string[];
  count?: number[];
};

const DataGridRow = ({ name, amount, time }: DataGridRowType) => {
  return (
    <div className='flex text-[12px] my-1'>
      <p className='w-[120px] pointer text-[#0964AD]'>{name}</p>
      <p className='w-[130px]'>{amount}</p>
      <p className='w-[130px] text-right'>{time}</p>
    </div>
  );
};

const DataGrid = ({ data }: DataGridType) => {
  return (
    <div>
      {data.map((row, index) => {
        return (
          <DataGridRow name={row.name} amount={row.amount} time={row.time} />
        );
      })}
    </div>
  );
};

const Home = () => {
  const [transactionData, setTransactionData] =
    useState<TransactionDataType | null>();

  const [userData, setUserData] = useState<UserDataType | null>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fromDate = useSelector((state: RootState) => state.date.fromDate);
  const toDate = useSelector((state: RootState) => state.date.toDate);

  const userRole = useSelector(
    (state: RootState) => state.user?.userData?.role
  );

  const getTransactionData = async () => {
    const formattedData: TransactionDataType = {};

    const params = {
      fromDate,
      toDate,
    };

    const res = await apiRequest('getAllTransaction', null, params);

    if (!res.data) {
      setTransactionData({});
      toast.error("Couldn't fetch transaction data");
      return;
    }

    let totalValue = 0;
    let totalVolume = 0;
    let transactionByDate: {
      [date: string]: number;
    } = {};

    res.data.map((transaction: any) => {
      console.log('transaction invoice amount : ', transaction?.invoiceAmount);

      if (transaction?.invoiceAmount) {
        totalValue += parseInt(transaction?.invoiceAmount);
        totalVolume += 1;

        const transactionDate = moment(transaction?.updatedDt).format(
          'MM/DD/YYYY'
        );

        if (transactionByDate[transactionDate]) {
          transactionByDate[transactionDate] += parseInt(
            transaction?.invoiceAmount
          );
        } else {
          transactionByDate[transactionDate] = parseInt(
            transaction?.invoiceAmount
          );
        }
      }
    });

    formattedData['value'] = totalValue;
    formattedData['volume'] = totalVolume;
    formattedData['time'] = Object.keys(transactionByDate);
    formattedData['amount'] = Object.values(transactionByDate);

    setTransactionData(formattedData);
  };

  const getUserData = async () => {
    const formattedData: UserDataType = {};

    const res = await apiRequest('getAllCustomers');

    if (!res.data) {
      toast.error("Couldn't fetch user data");
      return;
    }

    let totalValue = 0;
    let userData: {
      [date: string]: number;
    } = {};

    res.data.map((user: any) => {
      totalValue += 1;

      const userJoinedDate = moment(user?.createdDt).format('MM/DD/YYYY');

      if (userData[userJoinedDate]) {
        userData[userJoinedDate] += 1;
      } else {
        userData[userJoinedDate] = 1;
      }
    });

    formattedData['totalVendorsCount'] = totalValue;
    formattedData['time'] = Object.keys(userData);
    formattedData['count'] = Object.values(userData);

    setUserData(formattedData);
  };

  const navigateUser = () => {
    if (userRole) {
      if (userRole.includes(adminRole)) {
        navigate('/');
      } else if (userRole.includes(supportRole)) {
        navigate('/transaction');
      } else {
        dispatch(logoutUser());
        navigate('/login');
      }
    } else {
      dispatch(logoutUser());
      navigate('/login');
    }
  };

  useEffect(() => {
    getTransactionData();
    getUserData();
    navigateUser();
  }, [fromDate, toDate]);

  return (
    <AccessControl accessibleBy={[adminRole]}>
      <div className='w-full p-3'>
        <div>
          <SearchBar />
        </div>
        <div className='flex flex-wrap gap-10 mt-10'>
          <div className='w-full md:w-[45%]'>
            <Card>
              <div className='flex items-center ml-10'>
                <GrTransaction size={18} />
                <p className='text-[18px] font-semibold ml-2'>Exchange</p>
              </div>

              <div className='mt-4 flex justify-between mb-10'>
                <div className='mr-10'>
                  <p className='text-[12px] text-[#565656] font-semibold mb-1'>
                    Volume
                  </p>
                  <p className='text-[18px] font-bold'>
                    {transactionData?.volume}
                  </p>
                </div>
                <div className='ml-10'>
                  <p className='text-[12px] font-semibold mb-1 text-[#565656]'>
                    Value
                  </p>
                  <p className='text-[18px] font-bold'>
                    TZS {transactionData?.value}
                  </p>
                </div>
              </div>

              <div>
                <Line
                  height={'100%'}
                  data={{
                    labels: transactionData?.time,
                    datasets: [
                      {
                        label: 'Transaction Value',
                        data: transactionData?.amount,
                        borderColor: '#36A2EB',
                        backgroundColor: '#9BD0F5',
                      },
                    ],
                  }}
                />
              </div>

              {/* <div>
              <div className='mt-5 flex items-center justify-between mb-1'>
                <p className='text-[18px] font-semibold'>Current Transaction</p>
                <p className='text-[#0964AD] text-[10px] cursor-pointer underline font-semibold'>
                  View All
                </p>
              </div>

              <hr className='h-1' />
              {transactionData?.transaction && (
                <DataGrid data={transactionData.transaction} />
              )}
            </div> */}
            </Card>
          </div>
          <div className='w-full md:w-[45%]'>
            <Card>
              <div className='flex items-center ml-10'>
                <BsFillPeopleFill size={18} />
                <p className='text-[18px] font-semibold ml-2'>Users</p>
              </div>

              <div className='mt-4 flex justify-between mb-10'>
                <div className='mr-10'>
                  <p className='text-[12px] text-[#565656] font-semibold mb-1'>
                    Volume
                  </p>
                  <p className='text-[18px] font-bold'>
                    {userData?.totalVendorsCount}
                  </p>
                </div>
              </div>

              <div>
                <Line
                  // datasetIdKey='1'
                  height={'100%'}
                  data={{
                    labels: userData?.time,
                    datasets: [
                      {
                        label: 'Total users',
                        data: userData?.count,
                        borderColor: '#36A2EB',
                        backgroundColor: '#9BD0F5',
                      },
                    ],
                  }}
                />
              </div>

              {/* <div>
              <div className='mt-5 flex items-center justify-between mb-1'>
                <p className='text-[18px] font-semibold'>
                  New Vendor Application
                </p>
                <p className='text-[#0964AD] text-[10px] cursor-pointer underline font-semibold'>
                  View All
                </p>
              </div>

              <hr className='h-1' />
              {userData && <DataGrid data={userData.} />}
            </div> */}
            </Card>
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default Home;
