import clsx from 'clsx';
import { selectDropdownBorderColor } from '../../../constant/colors/border';
import UserCard from '../../../component/userCard';
import { Card } from '@mui/material';
import {
  placeholderText,
  settingsInputText,
  settingsText,
} from '../../../constant/colors/text';
import { submitButton } from '../../../constant/colors/buttons';

import { SiGooglemessages } from 'react-icons/si';
import { FaPhone } from 'react-icons/fa6';
import { CiUser } from 'react-icons/ci';
import settingLines from '../../../assets/setting-line.png';

const AuditTrail = () => {
  return (
    <div className='mt-3'>
      <div className=' my-3 flex justify-between flex-wrap'>
        <div className='text-[20px] font-semibold p-2'>All User</div>

        <div className='flex flex-wrap gap-x-4 mt-4'>
          <div>
            <input
              type='text'
              className={clsx(
                'border m-3 w-[300px] md:w-[200px] p-2 rounded-[3px] ',
                selectDropdownBorderColor
              )}
              placeholder='Search User'
            />
          </div>

          <div>
            <input
              type='date'
              className={clsx(
                'border p-2 rounded-[3px]  m-3 w-[300px] md:w-[200px] ',
                selectDropdownBorderColor
              )}
              placeholder='Date'
            />
          </div>
        </div>
      </div>
      <hr />
      <div className='my-2 flex flex-wrap justify-between mt-5'>
        <div className=' w-full m-2 lg:w-[60%]'>
          <UserCard isActive={true} />
          <UserCard isActive={false} />
          <UserCard isActive={false} />
          <UserCard isActive={false} />
          <UserCard isActive={false} />
        </div>

        <div className='w-full m-2  lg:w-[30%]'>
          <Card className='w-full rounded-[10px] p-10'>
            <div className='mb-2'>
              <div>
                <img
                  src='https://placehold.co/400'
                  className='w-[100px] h-[100px] rounded-[30px]'
                />
              </div>

              <div>
                <div className='text-[18px] font-semibold mb-3'>
                  Jumanne Rashidi
                </div>
              </div>
            </div>

            <img src={settingLines} className='w-[45%] my-6' />

            <div className='mt-5'>
              <div>
                <p className={clsx('text-[#9F9E9E] text-[10px] text-semibold')}>
                  {' '}
                  Activity
                </p>
                <p
                  className={clsx(
                    'text-blue text-[10px] text-semibold mt-[2px]'
                  )}
                >
                  Add New User
                </p>
              </div>

              <div className='mt-3'>
                <p className={clsx('text-[#9F9E9E] text-[10px] text-semibold')}>
                  {' '}
                  Activity Date
                </p>
                <p className={clsx(' text-[10px] text-semibold mt-[2px]')}>
                  {' '}
                  17 Jan 2020 | 08:23:23
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default AuditTrail;
