import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  settingsIconsBackgroundActive,
  settingsIconsBackgroundInActive,
} from '../../constant/colors/background';
import { useSearchParams } from 'react-router-dom';

type SettingsMenuCardProps = {
  icon: React.ReactNode;
  name: string;
  redirect: string;
  onClick: (name: string) => void;
};

const SettingsMenuCard = ({
  icon,
  name,
  redirect,
  onClick,
}: SettingsMenuCardProps) => {
  const [isActive, setIsActive] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setIsActive(searchParams.get('query') === redirect);
  }, [searchParams]);

  return (
    <div
      onClick={() => onClick(redirect)}
      className={clsx(
        'w-[95px] h-[95px] rounded-[10px] flex flex-col items-center justify-center cursor-pointer m-1',
        {
          [settingsIconsBackgroundActive]: isActive,
          [settingsIconsBackgroundInActive]: !isActive,
        }
      )}
    >
      {icon}
      <p className='text-[12px] font-semibold text-white text-center mt-1'>
        {name}
      </p>
    </div>
  );
};

export default SettingsMenuCard;
