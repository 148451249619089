import React, { useEffect, useState } from 'react';
import SearchBar from '../../component/Searchbar';
import { Card } from '@mui/material';
import clsx from 'clsx';
import { selectDropdownBorderColor } from '../../constant/colors/border';
import UserCard from '../../component/userCard';
import { submitButton } from '../../constant/colors/buttons';
import { SiGooglemessages } from 'react-icons/si';
import { FaCheckCircle, FaPhone, FaUserCircle } from 'react-icons/fa';
import { CiUser } from 'react-icons/ci';
import { settingsText } from '../../constant/colors/text';
import { PiGenderIntersexFill } from 'react-icons/pi';
import settingLines from '../../assets/setting-line.png';
import { createSearchParams, useNavigate } from 'react-router-dom';
import apiRequest from '../../utils/apiRequest';
import toast from 'react-hot-toast';
import moment from 'moment';
import { authBackgroundColor } from '../../constant/colors/background';
import AccessControl from '../../component/AccessControl';
import { adminRole, supportRole } from '../../constant/roles';

interface UserData {
  id?: string;
  userName?: string;
  password?: string | null;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  status?: string;
  location?: string | null;
  createdDt?: string;
  updatedDt?: string;
  temporary?: boolean;
  roles?: { id: string; name: string }[];
}

const NewUserCard = () => {
  return (
    <div className='w-full my-3 flex items-center justify-between p-2'>
      <div className='flex gap-2 items-center'>
        <img
          src='https://placehold.co/400'
          alt='profile'
          className='h-12 w-12 rounded-full'
        />
        <p className={clsx('text-[15px] ', settingsText)}>Asha Rashid</p>
      </div>
      <div className='text-[13px]'>12:00:23</div>
    </div>
  );
};

const Customer = () => {
  const [customerList, setCustomerList] = useState<UserData[]>([]);
  const [filterCustomerList, setFilterCustomerList] = useState<UserData[]>([]);
  const [currentData, setCurrentData] = useState<UserData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDisplay, setTotalDisplay] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  const [searchTerm, setSearchTerm] = useState<string | undefined | null>('');
  const [selectedUser, setSelectedUser] = useState<string | undefined>('');

  const [selectedUserDetails, setSelectedUserDetails] = useState<
    | {
        fullName?: string;
        phone?: string;
        id?: string;
        status?: string;
        gender?: string;
        createdAt?: string;
      }
    | undefined
  >();

  const navigate = useNavigate();

  const suspendAccount = (id?: string) => {
    const params = {
      id: '1',
    };

    const sParams = createSearchParams(params);
    navigate({ pathname: '/suspend', search: `?${sParams}` });
  };

  const viewCustomerTransaction = (id?: string) => {
    if (!id) return toast.error('Failed to fetch customer list');

    const params = {
      id,
    };

    const sParams = createSearchParams(params);
    navigate({ pathname: '/payment', search: `?${sParams}` });
  };

  const handleSelectUser = (id: string | undefined, data: {} | undefined) => {
    setSelectedUser(id);
    setSelectedUserDetails(data);
  };

  const getCustomerList = async () => {
    const res = await apiRequest('getAllCustomers');

    if (!res.data) {
      toast.error('Failed to fetch customer list');
      return;
    }

    setCustomerList(res.data);
    setCurrentData(res.data);
    setTotalPages(Math.ceil(res.data.length / totalDisplay));
  };

  const searchUsers = (search: string) => {
    const searchTerm = search.toLowerCase();
    setCurrentPage(1);
    const filteredCustomer = customerList.filter((user) => {
      const fullName = `${user?.firstName} ${user?.lastName}`;
      if (!fullName || !user?.id || !user?.userName) {
        return;
      }
      return (
        fullName.toLowerCase().includes(searchTerm) ||
        user?.id.toLowerCase().includes(searchTerm) ||
        user?.userName.toLowerCase().includes(searchTerm)
      );
    });

    setSearchTerm(search);
    setFilterCustomerList(filteredCustomer);
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setCurrentData(filterCustomerList);
      setTotalPages(Math.ceil(filterCustomerList.length / totalDisplay));
    } else {
      setCurrentData(customerList);
      setTotalPages(Math.ceil(customerList.length / totalDisplay));
    }
  }, [searchTerm]);

  return (
    <AccessControl accessibleBy={[adminRole, supportRole]}>
      <div className='w-full p-3'>
        <div>
          <SearchBar />
        </div>

        <div className='flex gap-1'>
          <Card className='w-full p-4'>
            <div className='mt-2 flex items-center justify-between '>
              <p className='text-[18px] font-semibold'>All Customer</p>

              <div className='flex flex-wrap'>
                <input
                  type='text'
                  className={clsx(
                    'border m-3 w-[300px] md:w-[200px] p-2 rounded-[3px]',
                    selectDropdownBorderColor
                  )}
                  placeholder='Search Customer'
                  onChange={(e) => searchUsers(e.target.value)}
                />
              </div>
            </div>

            <hr />

            <div className=' mt-2 flex justify-between gap-2'>
              <div className='w-full md:w-[50%] '>
                {currentData
                  .filter(
                    (val, index) =>
                      index >= currentPage * totalDisplay - totalDisplay &&
                      index < currentPage * totalDisplay
                  )
                  .map((customer: any) => (
                    <UserCard
                      isActive={selectedUser === customer?.id}
                      firstName={customer?.firstName}
                      lastName={customer?.lastName}
                      phone={customer?.userName}
                      id={customer?.id}
                      status={customer?.status}
                      gender={customer?.gender}
                      createdAt={customer?.createdDt}
                      handleSelect={handleSelectUser}
                    />
                  ))}

                <div>
                  <span
                    className={
                      'flex gap-2 w-[400px] items-center text-white p-4 float-right'
                    }
                  >
                    <button
                      className={clsx(
                        'px-5 py-2 rounded-lg font-bold',
                        authBackgroundColor,
                        {
                          'opacity-75': currentPage === 1,
                        }
                      )}
                      disabled={currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      Previous
                    </button>
                    <div className='text-black mx-3 font-black'>
                      {currentPage} of {totalPages}
                    </div>
                    <button
                      className={clsx(
                        'px-5 py-2 rounded-lg font-bold',
                        authBackgroundColor,
                        {
                          'opacity-75': currentPage === totalPages,
                        }
                      )}
                      disabled={currentPage === totalPages}
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      Next
                    </button>
                  </span>
                </div>
              </div>

              {selectedUser && (
                <div className='w-full md:w-[45%] '>
                  <Card className='p-3'>
                    <div className='flex justify-between mb-2'>
                      <div>
                        <div className='text-[18px] font-semibold mb-3'>
                          {selectedUserDetails?.fullName}
                        </div>
                        <div
                          className={clsx(
                            'text-[10px] font-semibold',
                            settingsText
                          )}
                        >
                          {/* <p className='flex gap-1 items-center'>
                          <SiGooglemessages size={10} /> jumanne@tiger.co.tz
                        </p> */}
                          <p className='flex gap-1 items-center'>
                            <FaPhone size={10} />
                            {selectedUserDetails?.phone}
                          </p>
                          <p className='flex gap-1 items-center'>
                            <PiGenderIntersexFill size={10} />
                            {selectedUserDetails?.gender}
                          </p>
                        </div>
                      </div>

                      <div>
                        <FaUserCircle size={80} className='text-gray-400' />
                      </div>
                    </div>

                    <img src={settingLines} className='my-3' />

                    {/* <div className='my-2'>
                    <div className='text-[#9F9E9E] text-[10px] font-semibold'>
                      Date of Birth
                    </div>
                    <div className='text-black text-[12px] '>03 March 1989</div>
                  </div> */}

                    <div className='my-2'>
                      <div className='text-[#9F9E9E] text-[10px] font-semibold'>
                        User ID
                      </div>
                      <div className='text-black text-[12px]  flex gap-1 items-center'>
                        {selectedUserDetails?.id}{' '}
                        <FaCheckCircle color='green' />
                      </div>
                    </div>

                    <div className='my-2'>
                      <div className='text-[#9F9E9E] text-[10px] font-semibold'>
                        Joined
                      </div>
                      <div className='text-black text-[12px] '>
                        {moment(selectedUserDetails?.createdAt).format(
                          'DD MMMM YYYY'
                        )}
                      </div>
                    </div>

                    {selectedUserDetails?.status &&
                    selectedUserDetails?.status?.toLowerCase() === 'active' ? (
                      <div className='my-2'>
                        <p className='text-[#039930] font-semibold text-[17px]'>
                          This account is active
                        </p>
                      </div>
                    ) : (
                      <div className='my-2'>
                        <p className='text-[#990303] font-semibold text-[17px]'>
                          This account is suspended
                        </p>
                      </div>
                    )}
                  </Card>

                  <div className='mt-10 flex justify-between'>
                    <div
                      className={clsx(
                        'rounded-[3px] w-[150px] h-[40px] flex items-center justify-center text-white font-medium cursor-pointer',
                        submitButton
                      )}
                      onClick={() => viewCustomerTransaction(selectedUser)}
                    >
                      View Transactions
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Card>
          {/* <div className='w-full lg:w-[30%]'>
          <Card className='p-4'>
            <div>
              <div className='text-[15px] text-black font-semibold'>
                New Members
              </div>
              <div className='text-[30px] text-black font-semibold'>234</div>
            </div>

            <hr className='my-2' />

            <div>
              <NewUserCard />
              <NewUserCard />
              <NewUserCard />
              <NewUserCard />
            </div>

            <hr className='my-5' />

            <div
              className={clsx(
                'p-3  w-[130px] text-[12px] flex items-center rounded-[3px] justify-center text-white cursor-pointer font-semibold',
                submitButton
              )}
            >
              View All
            </div>
          </Card>
        </div> */}
        </div>
      </div>
    </AccessControl>
  );
};

export default Customer;
