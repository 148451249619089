import React from 'react';
import VendorHeader from '../vendorHeader';

type BankDetailsCardProps = {
  title: string;
  data: string;
};

const BankDetailsCard = ({ title, data }: BankDetailsCardProps) => {
  return (
    <div className='cursor-pointer bg-blue-200 px-2 py-1 w-[200px] rounded-[5px] m-2'>
      <div className='text-[12px] text-gray-700 font-semibold'>{title}</div>
      <div className='text-[15px] text-black font-semibold'>{data}</div>
    </div>
  );
};

const BankDetails = () => {
  return (
    <div className='px-5'>
      <VendorHeader title='Bank Details' />

      <div>
        <BankDetailsCard title='Bank ' data='CRBD' />
        <BankDetailsCard title='Branch ' data='Azikiwe' />
        <BankDetailsCard title='Account Name ' data='Shoppers Co. Ltd' />
        <BankDetailsCard title='Account Number ' data='344 243 345  4' />
      </div>
    </div>
  );
};

export default BankDetails;
