import { combineReducers, configureStore } from '@reduxjs/toolkit';
import dateReducer from './dateSlice';
import userReducer from './userSlice';
import loadingReducer from './loadingSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'date'],
};

const rootReducer = combineReducers({
  date: dateReducer,
  user: userReducer,
  loading: loadingReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
