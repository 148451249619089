import React from 'react';

// icons

import clsx from 'clsx';
import { selectDropdownBorderColor } from '../../../constant/colors/border';
import UserCard from '../../../component/userCard';
import { Card } from '@mui/material';
import {
  placeholderText,
  settingsInputText,
  settingsText,
} from '../../../constant/colors/text';
import { submitButton } from '../../../constant/colors/buttons';

import { SiGooglemessages } from 'react-icons/si';
import { FaPhone } from 'react-icons/fa6';
import { CiUser } from 'react-icons/ci';
import settingLines from '../../../assets/setting-line.png';
import CategoryCard from '../../../component/categoryCard';
import { BiPlus } from 'react-icons/bi';
import { BsPlusSquareFill } from 'react-icons/bs';

const Categories = () => {
  return (
    <div className='mt-3'>
      <div className=' my-3 flex justify-between flex-wrap'>
        <div className=' pl-3 text-[20px] font-semibold'>All Categories</div>

        <div className='flex flex-wrap gap-x-4 items-center'>
          <div>
            <p className='text-[15px] font-semibold'>Total Categories: 102</p>
          </div>
          <div>
            <input
              className={clsx(
                'border m-3 w-full md:w-[200px] p-2 rounded-[3px]',
                selectDropdownBorderColor
              )}
              type='text'
              placeholder='Search Category'
            />
          </div>
        </div>
      </div>
      <hr />
      <div className='my-2 flex  flex-wrap justify-between mt-5 m-3'>
        <div className='w-full my-2 md:w-[30%]'>
          <CategoryCard isActive={true} />
          <CategoryCard isActive={false} />
          <CategoryCard isActive={false} />
          <CategoryCard isActive={false} />
        </div>

        <div className='w-full my-2 md:w-[30%]'>
          <Card className='w-full rounded-[10px] p-10'>
            <div className='w-full h-[150px] flex items-center justify-center'>
              <div>
                <label className='file-input-label'>
                  <div className='relative group'>
                    <img
                      className='w-[80px] h-[80px] rounded-full'
                      src='https://placehold.co/400'
                      alt='Upload'
                    />

                    <div
                      className='w-[80px] h-[80px] rounded-full absolute border items-center justify-center top-0 cursor-pointer bg-black opacity-70
                    hidden group-hover:flex 
                    '
                    >
                      <BsPlusSquareFill size={15} color='#fff' />
                    </div>
                  </div>
                  <input type='file' className='hidden' id='file-input' />
                </label>

                <div>
                  <p
                    className={clsx(
                      'text-[23px] font-medium text-center',
                      settingsText
                    )}
                  >
                    Shopping
                  </p>
                  <p className='text-[12px] text-[#565656] text-center -mt-1'>
                    230 Vendors
                  </p>
                </div>
              </div>
            </div>

            <div className='mt-10'>
              <div
                className={clsx(
                  'w-full p-2 rounded-[3px] text-center text-[12px] text-white cursor-pointer',
                  submitButton
                )}
              >
                View Vendor
              </div>
            </div>

            <div className='mt-2'>
              <div className='w-full p-2 rounded-[3px] bg-[#990303] text-center text-[12px] text-white cursor-pointer'>
                Disable
              </div>
            </div>
          </Card>
        </div>

        <div className='w-full my-2 md:w-[30%]'>
          <Card className='w-full rounded-[10px] p-10'>
            <div className='w-full h-[150px] flex items-center justify-center'>
              <div className='flex flex-col items-center'>
                <div>
                  <p
                    className={clsx(
                      'text-[23px] font-medium text-center mb-10'
                    )}
                  >
                    Add Category
                  </p>
                </div>
                <label className='file-input-label'>
                  <div className='relative'>
                    <img
                      className='w-[80px] h-[80px] rounded-full'
                      src='https://placehold.co/400'
                      alt='Upload'
                    />

                    <div className='w-[80px] h-[80px] rounded-full absolute items-center justify-center top-0 cursor-pointer bg-[#363636] flex border'>
                      <BsPlusSquareFill size={15} color='#fff' />
                    </div>
                  </div>
                  <input type='file' className='hidden' id='file-input' />
                </label>

                <div>
                  <p className='text-[12px] text-[#565656] text-center mt-2'>
                    Add icon
                  </p>
                </div>
              </div>
            </div>

            <div className='mt-[50px]'>
              <input
                className={clsx(
                  'border mb-2 w-full p-2 rounded-[3px]',
                  selectDropdownBorderColor
                )}
                type='text'
                placeholder='Name Category'
              />
              <div
                className={clsx(
                  'w-full p-2 rounded-[3px] text-center text-[12px] text-white cursor-pointer',
                  submitButton
                )}
              >
                Add
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Categories;
