import React from 'react';
import VendorHeader from '../vendorHeader';
import clsx from 'clsx';
import { submitButton } from '../../../../constant/colors/buttons';

type ContactsCardProps = {
  title: string;
  data: string;
};

const ContactsCard = ({ title, data }: ContactsCardProps) => {
  return (
    <div className='cursor-pointer bg-blue-100 px-2 py-1 w-[200px] rounded-[5px] m-2'>
      <div className='text-[12px] text-gray-700 font-semibold'>{title}</div>
      <div className='text-[15px] text-black font-semibold'>{data}</div>
    </div>
  );
};

const Contacts = () => {
  return (
    <div className='px-5'>
      <VendorHeader title='Bank Details' />

      <div>
        <ContactsCard title='Phone ' data='+255 723 343 343' />
        <ContactsCard title='Email ' data='Athumani@shoppers.co.tz' />
        <ContactsCard title='Physical address ' data='Magomeni Morocco' />
      </div>

      <div className='relative'>
        <img
          className='w-full h-[300px] object-cover rounded-lg'
          src='https://placehold.co/600x400'
        />

        <span
          className={clsx(
            ' absolute top-2 right-3 cursor-pointer rounded-[5px] px-5 py-3 text-white font-semibold',
            submitButton
          )}
        >
          Open Maps
        </span>
      </div>
    </div>
  );
};

export default Contacts;
