import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import AuthBase from '../../component/authBase';
import logo from '../../assets/logo.jpeg';
import { authBackgroundColor } from '../../constant/colors/background';
import { footerText } from '../../constant/footer';
import apiRequest from '../../utils/apiRequest';
import toast from 'react-hot-toast';
import validateEmail from '../../utils/validateEmail';
import { useDispatch, useSelector } from 'react-redux';
import { authSuccessful, logoutUser } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { adminRole, supportRole } from '../../constant/roles';
const Login = () => {
  const [authData, setAuthData] = useState({
    email: '',
    password: '',
  });
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.user);

  const dispatch = useDispatch();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAuthData((oldData) => ({
      ...oldData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    console.log(authData, 'Auth Data');

    if (!authData.email || !authData.password) {
      toast.error('Please enter both email and password');
      return;
    }

    if (!validateEmail(authData.email)) {
      toast.error('Please enter a valid email');
      return;
    }

    const reqBody = {
      username: authData.email,
      password: authData.password,
    };

    const res = await apiRequest('signIn', reqBody);

    if (!res.data) {
      toast.error('Invalid email or password');
      return;
    }

    const data = {
      token: res.data?.token,
      refreshToken: res.data?.refreshToken,
      refreshExpiry: res.data?.refreshTokenExpiry,
      userData: {
        email: res.data?.username,
        id: res.data?.id,
        role: res.data?.roles,
      },
    };

    if (res.data?.roles) {
      dispatch(authSuccessful(data));

      if (res.data?.roles.includes(adminRole)) {
        navigate('/');
        toast.success('Login successful');
      } else if (res.data?.roles.includes(supportRole)) {
        navigate('/transaction');
        toast.success('Login successful');
      } else {
        dispatch(logoutUser());
        navigate('/login');
        toast.error('You cannot access the portal');
      }
    } else {
      dispatch(logoutUser());
      navigate('/login');
    }
  };

  useEffect(() => {
    if (userData.token) navigate('/transaction');
  }, [userData]);

  return (
    <AuthBase>
      <div>
        <div className='flex flex-col gap-3 text-white'>
          <img
            src={logo}
            className='w-[100px] h-[100px] flex self-center mb-10 mix-blend-screen	'
          />
          <input
            className={`border border- bg-[${authBackgroundColor}] w-[448px] h-[70px] rounded-[5px] px-5 text-black`}
            placeholder='Email'
            type='email'
            name='email'
            onChange={handleInputChange}
          />

          <input
            className={`border border- bg-[${authBackgroundColor}] w-[448px] h-[70px] rounded-[5px] px-5 text-black`}
            placeholder='Password'
            type='password'
            name='password'
            onChange={handleInputChange}
          />

          <div className='flex justify-between underline  text-xs font-bold my-2'>
            <span className='cursor-pointer'>Can't access account?</span>
          </div>

          <div>
            <button
              className='bg-[#505050] w-[448px] h-[70px] rounded-[5px] text-white font-bold'
              onClick={handleSubmit}
            >
              Login
            </button>
          </div>
          <p className='text-center mt-20'>{footerText}</p>
        </div>
      </div>
    </AuthBase>
  );
};

export default Login;
