import React from 'react';
import { RiFileExcel2Fill } from 'react-icons/ri';

type SecondarySearchHeaderProps = {
  title: string;
  type?: string;
};

const SecondarySearchHeader = ({ title }: SecondarySearchHeaderProps) => {
  return (
    <div className='w-[96%]'>
      <div className='flex justify-between px-3'>
        <p className='text-[30px] font-semibold'>{title}</p>

        <div className='flex items-center gap-3'>
          <div className='flex gap-2 items-center cursor-pointer hidden'>
            <RiFileExcel2Fill color='#0964AD' size={30} />
            <span className=' text-[#0964AD] font-bold text-[12px]'>
              EXPORT
            </span>
          </div>

          <span className='h-[30px] border border-[#565656]  hidden' />
          <div className='hidden'>
            <span className='text-[17px] font-semibold text-[#565656]'>
              Filter:{' '}
            </span>

            <span>
              <select className='border-2 border-gray-500 rounded w-[300px] p-2 '>
                <option>Today</option>
              </select>
            </span>
          </div>
        </div>
      </div>

      <hr className='border-none h-[2px] my-5 bg-gray-400' />
    </div>
  );
};

export default SecondarySearchHeader;
