import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import dateFormat from '../constant/dateFormat';

export interface DateState {
  fromDate: string;
  toDate: string;
  selectedDateType: number;
}

const initialState: DateState = {
  fromDate: moment(new Date()).format(dateFormat),
  toDate: moment(new Date()).format(dateFormat),
  selectedDateType: 2,
};

export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    fromDateData: (state, action: PayloadAction<string>) => {
      state.fromDate = action.payload;
    },
    toDateData: (state, action: PayloadAction<string>) => {
      state.toDate = action.payload;
    },

    selectedDateTypeData: (state, action: PayloadAction<number>) => {
      state.selectedDateType = action.payload;
    },
  },
});

export const { fromDateData, toDateData, selectedDateTypeData } =
  dateSlice.actions;

export default dateSlice.reducer;
