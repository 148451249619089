import React from 'react';

// icons

import clsx from 'clsx';
import { selectDropdownBorderColor } from '../../../constant/colors/border';
import UserCard from '../../../component/userCard';
import { Card } from '@mui/material';
import {
  placeholderText,
  settingsInputText,
  settingsText,
} from '../../../constant/colors/text';
import { submitButton } from '../../../constant/colors/buttons';

import { SiGooglemessages } from 'react-icons/si';
import { FaPhone } from 'react-icons/fa6';
import { CiUser } from 'react-icons/ci';
import settingLines from '../../../assets/setting-line.png';
import { createSearchParams, useNavigate } from 'react-router-dom';

const Users = () => {
  return (
    <div className='mt-3'>
      <div className=' my-3 flex justify-between flex-wrap'>
        <div className=' pl-3 text-[20px] font-semibold'>All Users</div>

        <div className='flex flex-wrap gap-x-4'>
          <div>
            <select
              className={clsx(
                'border m-3 w-[300px] md:w-[200px] p-2 rounded-[3px]',
                selectDropdownBorderColor
              )}
            >
              <option>10</option>
              <option>20</option>
              <option>30</option>
            </select>
          </div>

          <div>
            <select
              className={clsx(
                'border m-3 w-[300px] md:w-[200px] p-2 rounded-[3px]',
                selectDropdownBorderColor
              )}
            >
              <option>10</option>
              <option>20</option>
              <option>30</option>
            </select>
          </div>
        </div>
      </div>
      <hr />
      <div className='my-2 flex  flex-wrap justify-between mt-5 m-3'>
        <div className='w-full my-2 lg:w-[30%]'>
          <UserCard isActive={true} />
          <UserCard isActive={false} />
          <UserCard isActive={false} />
          <UserCard isActive={false} />
          <UserCard isActive={false} />
        </div>

        <div className='w-full my-2 lg:w-[30%]'>
          <Card className='w-full rounded-[10px] p-10'>
            <div className='flex justify-between mb-2'>
              <div>
                <div className='text-[18px] font-semibold mb-3'>
                  Jumanne Rashidi
                </div>
                <div
                  className={clsx('text-[10px] font-semibold', settingsText)}
                >
                  <p className='flex gap-1 items-center'>
                    <SiGooglemessages size={10} /> jumanne@tiger.co.tz
                  </p>
                  <p className='flex gap-1 items-center'>
                    <FaPhone size={10} />
                    +255 647 343 333
                  </p>
                  <p className='flex gap-1 items-center'>
                    <CiUser size={10} />
                    Admin
                  </p>
                </div>
              </div>

              <div>
                <img
                  src='https://placehold.co/400'
                  className='w-[80px] h-[80px] rounded-[30px]'
                />
              </div>
            </div>

            <img src={settingLines} className='w-[45%] my-6' />

            <div className='flex justify-between'>
              <div>
                <p className={clsx('text-[#9F9E9E] text-[10px] text-semibold')}>
                  {' '}
                  Added
                </p>
                <p
                  className={clsx(
                    'text-black text-[10px] text-semibold mt-[2px]'
                  )}
                >
                  {' '}
                  23 May 2019 | 08:23:23
                </p>
              </div>

              <div>
                <p className={clsx('text-[#9F9E9E] text-[10px] text-semibold')}>
                  {' '}
                  Added By
                </p>
                <p
                  className={clsx(
                    ' text-[10px] text-semibold mt-[2px]',
                    settingsText
                  )}
                >
                  {' '}
                  Salum Mnyupe
                </p>
              </div>
            </div>
            <div className='flex justify-between mt-5'>
              <div>
                <p className={clsx('text-[#9F9E9E] text-[10px] text-semibold')}>
                  {' '}
                  Previous Number
                </p>
                <p
                  className={clsx(
                    'text-black text-[10px] text-semibold mt-[2px]'
                  )}
                >
                  {' '}
                  +255 647 343 330
                </p>
              </div>

              <div>
                <p className={clsx('text-[#9F9E9E] text-[10px] text-semibold')}>
                  {' '}
                  Date Changed
                </p>
                <p className={clsx(' text-[10px] text-semibold mt-[2px]')}>
                  {' '}
                  17 Jan 2020 | 08:23:23
                </p>
              </div>
            </div>

            <div className='mt-10'>
              <div className='w-full p-2 rounded-[3px] bg-[#990303] text-center text-[12px] text-white cursor-pointer'>
                Freeze Account
              </div>
            </div>
          </Card>
        </div>

        <div className='w-full my-2 lg:w-[30%] '>
          <Card className='p-2'>
            <p className='text-[23px] font-semibold mt-3'>Add User</p>

            <div className={clsx('w-full mt-3')}>
              <input
                type='text'
                placeholder='Full Name'
                className={clsx(
                  'border w-full p-2 rounded-[3px] my-1 font-semibold',
                  selectDropdownBorderColor,
                  settingsInputText,
                  placeholderText
                )}
              />
              <input
                type='text'
                placeholder='Phone Number'
                className={clsx(
                  'border w-full p-2 rounded-[3px] my-1 font-semibold',
                  selectDropdownBorderColor,
                  settingsInputText,
                  placeholderText
                )}
              />

              <select
                className={clsx(
                  'border w-full my-1 p-2 rounded-[3px]font-semibold',
                  selectDropdownBorderColor,
                  settingsInputText,
                  placeholderText
                )}
              >
                <option>Role</option>
                <option>20</option>
                <option>30</option>
              </select>

              <select
                className={clsx(
                  'border w-full my-1 p-2 rounded-[3px]font-semibold',
                  selectDropdownBorderColor,
                  settingsInputText,
                  placeholderText
                )}
              >
                <option>Country</option>
                <option>20</option>
                <option>30</option>
              </select>

              <div
                className={clsx(
                  'w-full my-5 p-2 text-center rounded-[3px] font-semibold text-white cursor-pointer',
                  submitButton
                )}
              >
                Add
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Users;
