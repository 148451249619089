import React, { useEffect, useState } from 'react';
import SearchBar from '../../component/Searchbar';
import SecondarySearchHeader from '../../component/secondaryHeader';

import BasicTableWithSelection from '../../component/tables/basicTableWithSelection';

import BasicTable from '../../component/tables/basicTable';
import { ColumnDef } from '@tanstack/react-table';

import { BiCheckDouble } from 'react-icons/bi';
import { PiWarningCircle } from 'react-icons/pi';
import { TbAlertTriangleOff } from 'react-icons/tb';
import { LuUndo2 } from 'react-icons/lu';
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { AiFillPieChart } from 'react-icons/ai';
import { HiOutlineDocument, HiOutlineDocumentText } from 'react-icons/hi';

import Card from '../../component/Card';
import clsx from 'clsx';
import {
  cardDetailsSecondaryText,
  cardDetailsText,
} from '../../constant/colors/text';
import {
  authBackgroundColor,
  tableHeaderBackgroundColor,
} from '../../constant/colors/background';
import { Bar } from 'react-chartjs-2';
import { primaryIconColor, secondaryIconColor } from '../../constant/icons';
import apiRequest from '../../utils/apiRequest';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import toast from 'react-hot-toast';
import AccessControl from '../../component/AccessControl';
import { adminRole } from '../../constant/roles';

type Sales = {
  id: string;
  vendorProfile: string;
  vendorName: string;
  totalSales: string;
  date: string;
  status: string;
};
type SalesCardTable = {
  customer: string;
  vendor: string;
  amount: string;
};

interface FormattedDataType {
  [merchantId: string]: {
    totalSales: number;
    name: string;
    id: string;
  };
}

type SalesDataInDetailsTypeAllVendor = {
  totalSales: number;
  name: string;
  id: string;
};

type SalesDataInDetailsTypeOneVendor = {
  date: string;
  vendorName: string;
  vendorProfile: string;
  totalSales: string;
  totalVolume: string;
  transactionTime: string[];
  transactionVolume: number[];
  disbursedStatus: string;
  transactionData?: [];
};

const Sales = () => {
  const [selectedRow, setSelectedRow] = useState<null | Sales>();
  const [salesData, setSalesData] = useState<
    null | SalesDataInDetailsTypeAllVendor[]
  >();
  const [salesDataInDetails, setSalesDataInDetails] = useState<
    null | SalesDataInDetailsTypeAllVendor[]
  >();
  const [currentActiveData, setCurrentActiveData] = useState(0);

  // store

  const fromDate = useSelector((state: RootState) => state.date.fromDate);
  const toDate = useSelector((state: RootState) => state.date.toDate);

  const columns = React.useMemo<ColumnDef<SalesDataInDetailsTypeAllVendor>[]>(
    () => [
      {
        accessorKey: 'name',
        id: 'vendor',
        cell: ({ row }) => {
          return (
            <div
              // onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.name}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.totalSales,
        id: 'totalSales',
        cell: ({ row }) => {
          return (
            <div
              // onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.totalSales}
            </div>
          );
        },
      },
    ],
    []
  );

  const transactionColumns = React.useMemo<ColumnDef<SalesCardTable>[]>(
    () => [
      {
        accessorKey: 'customer',
        id: 'customer',
        cell: ({ row }) => {
          return (
            <p className='text-xs text-blue-500'>{row.original.customer}</p>
          );
        },
      },
      {
        accessorKey: 'vendor',
        id: 'vendor',
        cell: ({ row }) => {
          return (
            <img
              className='w-5 h-5 rounded-full'
              src='https://placehold.co/400'
            />
          );
        },
      },
      {
        id: 'amount',
        accessorKey: 'amount',
      },
    ],
    []
  );

  const transactionRows = [
    {
      customer: 'User 1',
      vendor: 'User 1',
      amount: 'User 1',
    },
    {
      customer: 'User 1',
      vendor: 'User 1',
      amount: 'User 1',
    },
    {
      customer: 'User 1',
      vendor: 'User 1',
      amount: 'User 1',
    },
    {
      customer: 'User 1',
      vendor: 'User 1',
      amount: 'User 1',
    },
    {
      customer: 'User 1',
      vendor: 'User 1',
      amount: 'User 1',
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: 'Bar Chart Example',
      },
    },
  };

  const handleNext = () => {
    if (!salesDataInDetails) return;
    if (currentActiveData === salesDataInDetails?.length - 1) return;
    setCurrentActiveData((val) => val + 1);
  };
  const handlePrev = () => {
    if (!salesDataInDetails) return;
    if (currentActiveData === 0) return;
    setCurrentActiveData((val) => val - 1);
  };

  const getSalesData = async () => {
    const params = {
      fromDate: fromDate,
      toDate: toDate,
    };

    const res = await apiRequest('getAllTransaction', null, params);

    if (!res.data) {
      toast.error('Failed to get data!');
      setSalesData([]);
      return;
    }

    const formattedData: FormattedDataType = {};

    res.data.map((data: any) => {
      if (data?.merchantId && data?.invoiceAmount) {
        if (formattedData[data.merchantId]) {
          formattedData[data.merchantId].totalSales += parseInt(
            data.invoiceAmount
          );
        } else {
          formattedData[data.merchantId] = {
            id: data.merchantId,
            totalSales: parseInt(data.invoiceAmount),
            name: data.merchantName,
          };
        }
      }
    });

    console.log('formattedData', formattedData);

    setSalesData(Object.values(formattedData));
  };

  useEffect(() => {
    getSalesData();
  }, [fromDate, toDate]);

  return (
    <AccessControl accessibleBy={[adminRole]}>
      <div>
        <SearchBar />
        <SecondarySearchHeader title='Sales' />

        <div className='flex flex-wrap lg:flex-nowrap w-full'>
          <div
            className={clsx({
              'w-full': true,
            })}
          >
            {salesData && (
              <BasicTableWithSelection columns={columns} row={salesData} />
            )}
          </div>

          {/* <div className='w-full lg:w-[25%]'>
          {salesDataInDetails && (
            <Card>
              <div className='flex justify-between'>
                <div>
                  <p className='text-[22px] font-semibold'>Date</p>
                  <p className='text-[#565656] text-[12px] font-semibold '>
                    {salesDataInDetails[currentActiveData]['date']}
                  </p>
                </div>
                <div>
                  <p className='text-[22px] font-semibold'>All</p>
                  <p className='text-[#565656] text-[12px] font-semibold '>
                    Vendors
                  </p>
                </div>
              </div>
              <hr className='my-4' />

              <div
                className={clsx(
                  'w-full min-h-[70px] rounded-[5px] flex items-center p-2 justify-between',
                  tableHeaderBackgroundColor
                )}
              >
                <div>
                  <div className='text-[10px] font-semibold text-white'>
                    Total Sales:
                  </div>
                  <div className='text-[15px] font-semibold text-white'>
                    TZS {salesDataInDetails[currentActiveData]?.totalSales}
                  </div>
                </div>

                <div className='border-white border h-10' />

                <div>
                  <div className='text-[10px] font-semibold text-white text-right'>
                    Total Volume:
                  </div>
                  <div className='text-[15px] font-semibold text-white text-right'>
                    {salesDataInDetails[currentActiveData]?.totalVolume}
                  </div>
                </div>
              </div>

              <div className='my-1'>
                <Card>
                  {salesDataInDetails[currentActiveData]?.transactionTime &&
                    salesDataInDetails[currentActiveData]
                      ?.transactionVolume && (
                      <Bar
                        options={options}
                        data={{
                          labels:
                            salesDataInDetails[currentActiveData]
                              .transactionTime,
                          datasets: [
                            {
                              label: 'sales',
                              data: salesDataInDetails[currentActiveData]
                                ?.transactionVolume,
                              backgroundColor: 'rgb(102, 51, 151)',
                            },
                          ],
                        }}
                      />
                    )}
                </Card>

                <div className='my-3 flex justify-between'>
                  <div className='flex gap-3'>
                    <div className='cursor-pointer' onClick={handlePrev}>
                      <FaRegArrowAltCircleLeft
                        size={18}
                        className={clsx(primaryIconColor, {
                          'opacity-75': currentActiveData === 0,
                        })}
                      />
                    </div>
                    <div className='cursor-pointer' onClick={handleNext}>
                      <FaRegArrowAltCircleRight
                        size={18}
                        className={clsx(primaryIconColor, {
                          'opacity-75':
                            currentActiveData === salesDataInDetails.length - 1,
                        })}
                      />
                    </div>
                  </div>

                  <div
                    className={clsx(
                      'flex text-[14px] items-center gap-2',
                      secondaryIconColor
                    )}
                  >
                    <AiFillPieChart size={18} />
                    Insights
                  </div>
                </div>
              </div>

              <hr className='my-1' />

              {salesDataInDetails[currentActiveData]?.transactionData && (
                <div>
                  <div className='flex justify-between'>
                    <p className='text-[15px] font-semibold mb-2'>Sales</p>
                    <p
                      className={clsx(
                        'flex text-sm items-center  cursor-pointer',
                        secondaryIconColor
                      )}
                    >
                      {' '}
                      <HiOutlineDocumentText size={20} /> View All
                    </p>
                  </div>

                  <div className='-mt-7'>
                    <Card>
                      <BasicTable
                        row={
                          salesDataInDetails[currentActiveData]?.transactionData
                        }
                        columns={transactionColumns}
                      />
                    </Card>
                  </div>
                </div>
              )}
            </Card>
          )}
        </div> */}
        </div>
      </div>
    </AccessControl>
  );
};

export default Sales;
