import React from 'react';
import { authBackgroundColor } from '../../../constant/colors/background';
import clsx from 'clsx';
import { Pie } from 'react-chartjs-2';
import { Card } from '@mui/material';
import { selectDropdownBorderColor } from '../../../constant/colors/border';
import { ColumnDef } from '@tanstack/react-table';
import AlternateColorTable from '../../../component/tables/alternateColorsTableWithSelection';
import UserCard from '../../../component/userCard';

import { FiPhone } from 'react-icons/fi';
import { BsEnvelopeArrowUpFill } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import {
  submitButton,
  submitButtonRed,
} from '../../../constant/colors/buttons';
import {
  FaCheckCircle,
  FaHashtag,
  FaPhone,
  FaRegUserCircle,
} from 'react-icons/fa';
import settingLines from '../../../assets/setting-line.png';
import { PiGenderIntersexFill } from 'react-icons/pi';
import { SiGooglemessages } from 'react-icons/si';
import { settingsText } from '../../../constant/colors/text';

const Staff = () => {
  return (
    <div className='flex gap-2 flex-wrap'>
      <div className=' w-full lg:w-[70%]'>
        <Card className='px-3 py-5'>
          <div className='flex justify-between'>
            <div>
              <div className='text-[20px] font-semibold'>All Staff</div>
            </div>

            <div>
              <input
                className={clsx(
                  'border py-2 px-5 w-[250px] rounded-sm',
                  selectDropdownBorderColor
                )}
                type='text'
                placeholder='Search'
              />
            </div>
          </div>
          <hr className='my-2' />

          <div className='flex gap-2 justify-between'>
            <div className='w-[50%]'>
              <UserCard isActive={false} />
              <UserCard isActive={true} />
              <UserCard isActive={false} />
              <UserCard isActive={false} />
              <UserCard isActive={false} />
            </div>
            <div className='w-[45%]'>
              <Card className='p-3 pt-[50px] pb-[100px]'>
                <div className='flex justify-between mb-2'>
                  <div>
                    <img
                      src='https://placehold.co/400'
                      className='w-[80px] h-[80px] rounded-[30px]'
                    />
                  </div>

                  <div>
                    <div className='text-[18px] font-semibold mb-3'>
                      Jumanne Rashidi
                    </div>
                    <div
                      className={clsx(
                        'text-[10px] font-semibold',
                        settingsText
                      )}
                    >
                      <p className='flex gap-1 items-center'>
                        <FaRegUserCircle size={10} />
                        Managing Director
                      </p>
                      <p className='flex gap-1 items-center'>
                        <FaPhone size={10} />
                        +255 647 343 333
                      </p>
                      <p className='flex gap-1 items-center'>
                        <FaHashtag size={10} />
                        346423-00
                      </p>
                    </div>
                  </div>
                </div>

                <hr className='my-3' />

                <div className='my-2 flex justify-between'>
                  <div>
                    <div className='text-[#9F9E9E] text-[10px] font-semibold'>
                      Added by
                    </div>
                    <div className='text-black text-[12px] '>Jhon Samuel</div>
                  </div>
                  <div>
                    <div className='text-[#9F9E9E] text-[10px] font-semibold'>
                      Previous Role
                    </div>
                    <div className='text-black text-[12px] '>Null</div>
                  </div>
                </div>

                <div className='my-2'>
                  <div className='text-[#9F9E9E] text-[10px] font-semibold'>
                    Date
                  </div>
                  <div className='text-black text-[12px]  flex gap-1 items-center'>
                    23 May 2020 | 08:23:23
                  </div>
                </div>
              </Card>
              <div className='my-5 flex justify-between'>
                <div
                  className={clsx(
                    'w-[200px] text-white h-[50px] border flex items-center justify-center rounded-[5px] cursor-pointer',
                    submitButtonRed
                  )}
                >
                  Suspend
                </div>

                <div
                  className={clsx(
                    'w-[200px] h-[50px] text-white border flex items-center justify-center rounded-[5px] cursor-pointer',
                    submitButton
                  )}
                >
                  View Transaction
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className=' w-full lg:w-[25%]'>
        <div
          className={clsx(
            'flex justify-between py-5 px-3 my-2 text-white rounded-[5px]',
            authBackgroundColor
          )}
        >
          <div>
            <div className='text-[12px]'>Lifetime Sales</div>
            <div className='text-[20px]'>500</div>
          </div>
          <div className='h-[50px] border-l-0 border border-white' />
          <div>
            <div className='text-[12px]'>Lifetime Revenue</div>
            <div className='text-[20px]'>TZS 9,434,000</div>
          </div>
        </div>

        <div className='my-2'>
          <Card className='py-4 px-2'>
            <div>
              <div>
                <div className='text-[12px] text-gray-500 font-semibold'>
                  Evg Sale Volume/Day
                </div>
                <div className='font-bold mt-2 text-[18px] '>5 Transaction</div>
              </div>
              <hr className='my-5' />

              <div>
                <div className='text-[12px] text-gray-500 font-semibold'>
                  Evg Revenue/Day
                </div>
                <div className='font-bold text-[18px] mt-2'>TZS 18,868.00</div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Staff;
