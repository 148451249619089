import React from 'react';

type Props = {
  children: React.ReactNode;
};
const Card = (props: Props) => {
  return (
    <div className='min-h-20 shadow-xl w-full rounded-lg px-3 py-10'>
      {props.children}
    </div>
  );
};

export default Card;
