import clsx from 'clsx';
import React from 'react';
import { selectDropdownBorderColor } from '../../../../constant/colors/border';

type VendorHeaderProps = {
  title: string;
};
const VendorHeader = ({ title }: VendorHeaderProps) => {
  return (
    <div>
      <div className='flex justify-between items-center'>
        <div className='text-[15px] font-semibold'>{title}</div>
        <div>
          <select
            className={clsx(
              'border py-2 px-5 rounded-sm',
              selectDropdownBorderColor
            )}
          >
            <option>Select</option>
            <option>Select 1</option>
            <option>Select 2</option>
          </select>
        </div>
      </div>

      <hr className='my-2' />
    </div>
  );
};

export default VendorHeader;
