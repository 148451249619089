import { Card } from '@mui/material';
import React from 'react';
import { IoIosArrowRoundDown } from 'react-icons/io';
import { IoIosArrowRoundUp } from 'react-icons/io';

type AnalyticsCardProps = {
  title: string;
  type: 'transaction' | 'spenders' | 'sellers' | 'area';
  data?: any[];
};

type BigValueTransactionTableRowsProps = {
  name?: string;
  amount?: string;
  time?: string;
};

type BigTableRowsProps = {
  name?: string;
  amount?: string;
  up?: boolean;
};

type GlobalTableProps = {
  data: BigValueTransactionTableRowsProps[] | BigTableRowsProps[];
  type: 'transaction' | 'spenders' | 'sellers' | 'area';
};

// Transaction Table Rows

const BigValueTableRows = ({
  name,
  amount,
  time,
}: BigValueTransactionTableRowsProps) => {
  return (
    <div className='flex text-[11px] text-semibold gap-1 justify-between p-1'>
      <div>{name}</div>
      <div>{amount}</div>
      <div>{time}</div>
    </div>
  );
};

const BigTableRows = ({ name, amount, up }: BigTableRowsProps) => {
  return (
    <div className='flex text-[11px] text-semibold gap-1 justify-between p-1'>
      <div>{name}</div>
      <div className='gap-1 flex'>
        <div>{amount}</div>
        {/* <div>
          {up ? (
            <IoIosArrowRoundUp size={20} className=' text-green-600' />
          ) : (
            <IoIosArrowRoundDown size={20} className=' text-red-600' />
          )}
        </div> */}
      </div>
    </div>
  );
};

const BigValueTable = ({ data, type }: GlobalTableProps) => {
  return (
    <div>
      {type === 'transaction' &&
        data &&
        data.map((val: BigValueTransactionTableRowsProps) => (
          <BigValueTableRows
            name={val?.name}
            amount={val?.amount}
            time={val?.time}
          />
        ))}

      {type !== 'transaction' &&
        data &&
        data.map((val: BigTableRowsProps) => (
          <BigTableRows name={val?.name} amount={val?.amount} up={val?.up} />
        ))}
    </div>
  );
};

const bigValueTemp: BigValueTransactionTableRowsProps[] = [
  {
    name: 'Mahesh',
    amount: '1,00,00,000',
    time: '10:22 AM',
  },
  {
    name: 'Mahesh',
    amount: '1,00,00,000',
    time: '10:22 AM',
  },
  {
    name: 'Mahesh',
    amount: '1,00,00,000',
    time: '10:22 AM',
  },
  {
    name: 'Mahesh',
    amount: '1,00,00,000',
    time: '10:22 AM',
  },
];

const bigSpendersTemp: BigTableRowsProps[] = [
  {
    name: 'Mahesh',
    amount: '1,00,00,000',
    up: true,
  },
  {
    name: 'Mahesh',
    amount: '1,00,00,000',
    up: false,
  },
  {
    name: 'Mahesh',
    amount: '1,00,00,000',
    up: true,
  },
];

const highTradingTemp: BigTableRowsProps[] = [
  {
    name: 'Mahesh',
    up: true,
  },
  {
    name: 'Mahesh',
    up: false,
  },
  {
    name: 'Mahesh',
    up: true,
  },
];

// Spenders Table

const AnalyticsCard = ({ title, type, data }: AnalyticsCardProps) => {
  return (
    <Card className='w-full md:w-[45%] lg:w-[22%] m-2'>
      <div className='flex items-center justify-between px-1'>
        <p className='text-[13px] font-semibold'>{title}</p>
        {/* <p className='underline text-blue-400 text-[10px]'>View All</p> */}
      </div>
      <hr className='my-1' />

      <div>
        {type === 'transaction' && (
          <BigValueTable data={bigValueTemp} type='transaction' />
        )}
        {type === 'spenders' && (
          <BigValueTable data={data ? data : []} type='spenders' />
        )}

        {type === 'sellers' && (
          <BigValueTable data={data ? data : []} type='sellers' />
        )}

        {type === 'area' && (
          <BigValueTable data={highTradingTemp} type='area' />
        )}
      </div>
    </Card>
  );
};

export default AnalyticsCard;
