import clsx from 'clsx';
import React from 'react';
import { submitButton } from '../../../../../constant/colors/buttons';

const EditName = () => {
  return (
    <div>
      <input
        type='text'
        className='border-black border w-full rounded-[3px] p-2'
        placeholder='Enter Display Name'
      />

      <div
        className={clsx(
          'px-3 py-2 flex w-[100px] items-center justify-center rounded-[3px] cursor-pointer mt-2 text-white float-right',
          submitButton
        )}
      >
        Submit
      </div>
    </div>
  );
};

export default EditName;
