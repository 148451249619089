import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';

type AccessControlProps = {
  children?: React.ReactNode;
  accessibleBy: string[];
};

const AccessControl = ({ children, accessibleBy }: AccessControlProps) => {
  const [isRestricted, setIsRestricted] = useState(true);

  const navigation = useNavigate();
  const userRole: string[] = useSelector(
    (state: RootState) => state.user?.userData?.role
  );

  useEffect(() => {
    if (!userRole) {
      navigation('/login');
      console.log('redirect to login page');
    } else {
      const hasAccess = accessibleBy.some((role) => userRole.includes(role));
      if (hasAccess) {
        setIsRestricted(false);
      } else {
        setIsRestricted(true);
      }
    }
  }, [userRole]);

  return (
    <div>
      {isRestricted ? (
        <div className='w-full h-[calc(100vh-100px)] flex items-center justify-center text-2xl font-semibold text-red-600'>
          Access Denied
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default AccessControl;
