import clsx from 'clsx';
import React from 'react';
import { selectDropdownBorderColor } from '../../../constant/colors/border';
import { submitButtonRed } from '../../../constant/colors/buttons';
import { LiaTimesSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

const SuspendAccount = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <LiaTimesSolid
        color='red'
        size={30}
        className='mt-5 cursor-pointer float-right mr-5'
        onClick={goBack}
      />
      <div className='w-full h-screen flex items-center justify-center'>
        <div className='flex flex-col items-center'>
          <div className='text-[47px] font-semibold my-5'>Suspend</div>

          <img
            className='h-[100px] w-[100px] rounded-full my-5'
            src='https://placehold.co/400'
          />

          <div className='text-[30px]'>Jumanne Rashidi</div>
          <div>
            <select
              className={clsx(
                'my-10 w-[300px] p-5 border-[2px] rounded-[3px] ',
                selectDropdownBorderColor
              )}
            >
              <option>Select Reason</option>
              <option>10</option>
              <option>10</option>
              <option>10</option>
            </select>
          </div>

          <div>
            <select
              className={clsx(
                'w-[300px] p-5 border-[2px] rounded-[3px] ',
                selectDropdownBorderColor
              )}
            >
              <option>Length of Suspension</option>
              <option>1</option>
              <option>7</option>
              <option>10</option>
            </select>
          </div>

          <div
            className={clsx(
              'w-[300px] p-5 flex items-center justify-center text-white rounded-[3px] mt-5 cursor-pointer font-semibold',
              submitButtonRed
            )}
          >
            Suspend
          </div>
        </div>
      </div>
    </>
  );
};

export default SuspendAccount;
