import React, { useEffect, useState } from 'react';
import SettingsMenuCard from './settingsMenuCard';

// icons
import { HiUserGroup } from 'react-icons/hi2';
import { RiShapesLine } from 'react-icons/ri';
import { GrDocumentUser } from 'react-icons/gr';
import { FiSettings } from 'react-icons/fi';
import { PiUserFill } from 'react-icons/pi';
import { useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { selectDropdownBorderColor } from '../../constant/colors/border';
import UserCard from '../../component/userCard';
import { Card } from '@mui/material';
import {
  placeholderText,
  settingsInputText,
  settingsText,
} from '../../constant/colors/text';
import { submitButton } from '../../constant/colors/buttons';

import { SiGooglemessages } from 'react-icons/si';
import { FaPhone } from 'react-icons/fa6';
import { CiUser } from 'react-icons/ci';
import settingLines from '../../assets/setting-line.png';
import Users from './user';
import Categories from './categories';
import AuditTrail from './auditTrail';
import Configuration from './configuration';
import MyAccount from './myAccount/myAccount';

const Setting = () => {
  const [currentTab, setCurrentTab] = useState<string>('user');
  const [searchParams, setSearchParams] = useSearchParams();

  const menuIconSize = 35;
  const iconColor = '#fff';

  const settingsMenuOptions = [
    {
      icons: <HiUserGroup size={menuIconSize} color={iconColor} />,
      name: 'User',
      key: 'user',
    },
    {
      icons: <RiShapesLine size={menuIconSize} color={iconColor} />,
      name: 'Categories',
      key: 'categories',
    },
    {
      icons: <GrDocumentUser size={menuIconSize} color={iconColor} />,
      name: 'Audit trail',
      key: 'audit',
    },
    // {
    //   icons: <FiSettings size={menuIconSize} color={iconColor} />,
    //   name: 'Configuration',
    //   key: 'configuration',
    // },
    // {
    //   icons: <PiUserFill size={menuIconSize} color={iconColor} />,
    //   name: 'My Account',
    //   key: 'myAccount',
    // },
  ];

  const handleMenuHandleClick = (name: string) => {
    setSearchParams({ query: name });
  };

  useEffect(() => {
    const currentQuery = searchParams.get('query');

    if (currentQuery) {
      setCurrentTab(currentQuery);
    } else {
      setCurrentTab('user');
    }
  }, [searchParams]);

  return (
    <div className='w-full lg:w-[95%] mt-3'>
      <p className='text-[30px] font-semibold'>Settings</p>

      <div className='my-2 flex flex-wrap gap-x-3'>
        {settingsMenuOptions.map((option) => (
          <SettingsMenuCard
            name={option.name}
            icon={option.icons}
            redirect={option.key}
            onClick={handleMenuHandleClick}
          />
        ))}
      </div>

      {currentTab === 'user' && <Users />}
      {currentTab === 'categories' && <Categories />}
      {currentTab === 'audit' && <AuditTrail />}
      {currentTab === 'configuration' && <Configuration />}
      {currentTab === 'myAccount' && <MyAccount />}
    </div>
  );
};

export default Setting;
