import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
} from 'react-router-dom';
import Login from './page/login';
import Signup from './page/signup';
import Navbar from './component/Navbar';
import Home from './page/home';
import { PersistGate } from 'redux-persist/integration/react';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
} from 'chart.js';
import Transaction from './page/transaction';
import Sales from './page/sales';
import Analytics from './page/analytics';
import ViewData from './page/viewData';
import Setting from './page/settings';
import Customer from './page/customer';
import SuspendAccount from './page/customer/suspendAccount';
import Vendors from './page/vendors';
import VendorProfileBase from './page/vendors/vendorProfileBase';
import { persistor, store } from './store';
import { Provider } from 'react-redux';
import Payment from './page/payments';
import { Toaster } from 'react-hot-toast';
import GlobalSpinner from './page/GlobalSpinner';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route element={<Navbar />}>
        <Route path='/' element={<Home />} />
        <Route path='/transaction' element={<Transaction />} />
        <Route path='/sales' element={<Sales />} />
        <Route path='/payment' element={<Payment />} />
        <Route path='/analytics' element={<Analytics />} />
        <Route path='/viewData' element={<ViewData />} />
        <Route path='/setting' element={<Setting />} />
        <Route path='/customer' element={<Customer />} />
        <Route path='/suspend' element={<SuspendAccount />} />
        <Route path='/vendor' element={<Vendors />} />
        <Route path='/vendorProfile' element={<VendorProfileBase />} />
      </Route>

      <Route path='login' element={<Login />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <GlobalSpinner />
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
