import React, { useEffect, useState } from 'react';
import SearchBar from '../../component/Searchbar';
import BasicTable from '../../component/tables/basicTableWithSelection';
import { ColumnDef } from '@tanstack/react-table';

import { BiCheckDouble } from 'react-icons/bi';
import { PiWarningCircle } from 'react-icons/pi';
import { TbAlertTriangleOff } from 'react-icons/tb';
import { LuUndo2 } from 'react-icons/lu';
import downloadIcon from '../../assets/download-icon.png';
import { RxCross1 } from 'react-icons/rx';
import arrow from '../../assets/arrow.svg';

import Card from '../../component/Card';
import clsx from 'clsx';
import {
  cardDetailsSecondaryText,
  cardDetailsText,
} from '../../constant/colors/text';
import SecondarySearchHeader from '../../component/secondaryHeader';
import apiRequest from '../../utils/apiRequest';
import {
  submitButton,
  submitButtonDisabled,
} from '../../constant/colors/buttons';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';
import dateFormat from '../../constant/dateFormat';

type Payments = {
  id: number;
  from: string;
  fromProfile: string;
  to: string;
  points: string;
  amount: string;
  reference: string;
  date: string;
  status: string;
};

const Payment = () => {
  const [paymentDataList, setPaymentDataList] = useState<Payments[] | null>();
  const [selectedRow, setSelectedRow] = useState<null | number>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [transactionFilterData, setTransactionFilterData] = useState<
    Payments[] | null | undefined
  >([]);

  const navigate = useNavigate();

  const fromDate = useSelector((state: RootState) => state.date.fromDate);
  const toDate = useSelector((state: RootState) => state.date.toDate);

  const columns = React.useMemo<ColumnDef<Payments>[]>(
    () => [
      {
        accessorKey: 'from',
        header: () => 'From',
        id: 'from',
        cell: ({ row }) => {
          return <div className='cursor-pointer'>{row.original.from}</div>;
        },
      },
      {
        header: () => 'To',
        accessorFn: (row) => row.to,
        id: 'to',
        cell: ({ row }) => {
          return <div className='cursor-pointer'>{row.original.to}</div>;
        },
      },
      {
        accessorKey: 'point',
        header: () => 'Tokens',
        cell: ({ row }) => {
          return <div className='cursor-pointer'>{row.original.points}</div>;
        },
      },
      {
        accessorKey: 'amount',
        header: () => 'Amount',
        cell: ({ row }) => {
          return <div className='cursor-pointer'>{row.original.amount}</div>;
        },
      },
      {
        accessorKey: 'reference',
        header: () => 'Reference',
        cell: ({ row }) => {
          return <div className='cursor-pointer'>{row.original.reference}</div>;
        },
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        cell: ({ row }) => {
          const renderIcon = (iconName: string) => {
            switch (iconName.toLowerCase()) {
              case 'approved':
                return <BiCheckDouble size={30} className='text-green-800' />;
              case 'pending':
                return (
                  <PiWarningCircle size={30} className='text-yellow-500' />
                );
              case 'failed':
                return (
                  <TbAlertTriangleOff size={30} className='text-red-500' />
                );
              case 'reversed':
                return <LuUndo2 size={30} />;

              default:
                return;
            }
          };

          return (
            <div className='cursor-pointer'>
              {renderIcon(row.original.status)}
            </div>
          );
        },
      },
    ],
    []
  );

  const getPaymentsData = async () => {
    const userId = searchParams.get('id');
    if (fromDate && toDate && userId) {
      const params = {
        fromDate,
        toDate,
        customerId: userId,
      };

      const res = await apiRequest('getUserTransactionData', null, params);

      if (!res.data) {
        toast.error('Failed to get customer transaction');
        return;
      }

      const formattedData = res.data.map((data: any) => {
        return {
          id: data?.id,
          from: data?.customerName,
          to: data?.merchantName,
          points: data?.points,
          amount: data?.invoiceAmount,
          reference: data.invoiceId,
          status: data?.status,
          date: moment(data?.createdDt).format(dateFormat),
          time: moment(data?.createdDt).format('HH:mm:ss'),
        };
      });

      console.log(formattedData, 'formattedData');

      setPaymentDataList(formattedData);
    } else {
      navigate(-1);
    }
  };

  const handleStatusFilter = (status: string) => {
    setStatusFilter(status);

    if (status) {
      if (searchTerm) {
        const filteredData = transactionFilterData?.filter(
          (data) => data.status === status
        );
        setTransactionFilterData(filteredData);
      } else {
        const filteredData = paymentDataList?.filter(
          (data) => data.status === status
        );
        setTransactionFilterData(filteredData);
      }
    }
  };

  const handleSearch = (search: string) => {
    const query = search.toLowerCase();

    setSearchTerm(query);

    if (query) {
      if (statusFilter) {
        const filteredData = paymentDataList?.filter((data) => {
          return (
            (data.from?.toLocaleLowerCase().includes(query) ||
              data.to?.toLocaleLowerCase().includes(query) ||
              data.reference?.toLocaleLowerCase().includes(query)) &&
            data.status === statusFilter
          );
        });
        setTransactionFilterData(filteredData);
      } else {
        const filteredData = paymentDataList?.filter((data) => {
          return (
            data.from?.toLocaleLowerCase().includes(query) ||
            data.to?.toLocaleLowerCase().includes(query) ||
            data.reference?.toLocaleLowerCase().includes(query)
          );
        });
        setTransactionFilterData(filteredData);
      }
    } else {
      if (statusFilter) {
        handleStatusFilter(statusFilter);
      }
    }
  };

  useEffect(() => {
    getPaymentsData();
  }, [fromDate, toDate]);

  return (
    <div>
      <SearchBar />

      <div className='flex gap-3 float-right mr-20 mt-3'>
        <div>
          <input
            type='text'
            className='border border-gray-400 py-2 rounded-md px-1 w-[250px]'
            placeholder='Search by from, to or reference'
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div>
          <select
            className='border border-gray-400 py-2 rounded-md px-1 w-[250px]'
            onChange={(e) => handleStatusFilter(e.target.value)}
            value={statusFilter}
          >
            <option value=''>Status</option>
            <option value='APPROVED'>Successful</option>
            <option value='PENDING'>Pending</option>
          </select>
        </div>
      </div>

      <SecondarySearchHeader title='Customer Transaction' />

      <div className='flex flex-wrap lg:flex-nowrap'>
        <div
          className={clsx({
            'w-full lg:w-[70%]': selectedRow,
            'w-full': !selectedRow,
          })}
        >
          {paymentDataList && (
            <BasicTable
              columns={columns}
              row={
                statusFilter || searchTerm
                  ? transactionFilterData
                  : paymentDataList
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Payment;
