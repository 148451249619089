import clsx from 'clsx';
import React, { useState } from 'react';
import { selectDropdownBorderColor } from '../../../../../constant/colors/border';
import { submitButton } from '../../../../../constant/colors/buttons';
import { authBackgroundColor } from '../../../../../constant/colors/background';

const EditCategory = () => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);

  const handleSelect = (index: number) => {
    if (selectedCategories.includes(index)) {
      setSelectedCategories((prev) => prev.filter((val) => val !== index));
    } else {
      setSelectedCategories((prev) => [...prev, index]);
    }
  };

  const categoryType = [
    {
      index: 1,
      name: 'Beauty',
      image: 'https://placehold.co/400',
    },
    { index: 2, name: 'Filling Station', image: 'https://placehold.co/400' },
    { index: 3, name: 'Health', image: 'https://placehold.co/400' },
    { index: 4, name: 'Leisure', image: 'https://placehold.co/400' },
    { index: 5, name: 'NGO', image: 'https://placehold.co/400' },
  ];

  return (
    <div>
      <div className='font-semibold text-[14px]'>Select Business Type</div>

      <div className='flex flex-wrap gap-2 mt-5 '>
        {categoryType.map((val) => {
          return (
            <div
              className={clsx(
                ' m-1 p-2 border rounded-[3px] flex flex-col items-center cursor-pointer',
                {
                  [selectDropdownBorderColor]: selectedCategories.includes(
                    val.index
                  ),
                }
              )}
              onClick={() => handleSelect(val.index)}
            >
              <img src={val?.image} className='w-20 h-20 rounded-full' />

              <div className='text-[12px] mt-3'>{val.name}</div>
            </div>
          );
        })}
      </div>

      <div
        className={clsx(
          'px-3 py-2 flex w-[100px] items-center justify-center rounded-[3px] cursor-pointer mt-2 text-white float-right',
          submitButton
        )}
      >
        Submit
      </div>
    </div>
  );
};

export default EditCategory;
