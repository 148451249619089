type UrlTypes = {
  [key: string]: {
    path: string;
    method: string;
  };
};

const urls: UrlTypes = {
  signIn: {
    path: 'auth/merchantsignin',
    method: 'POST',
  },
  getAllTransaction: {
    path: 'app/alltransactions',
    method: 'GET',
  },
  getAllCustomers: {
    path: 'app/customerlist',
    method: 'GET',
  },
  getVendorList: {
    path: 'app/merchantlist',
    method: 'GET',
  },
  getUserProfileById: {
    path: 'app/userProfiledetails',
    method: 'GET',
  },
  getUserTransactionData: {
    path: 'app/gettransactions',
    method: 'GET',
  },
  bigSpenders: {
    path: 'app/highest-purchase-customer',
    method: 'GET',
  },
  bigSellers: {
    path: 'app/highest-sale-merchant',
    method: 'GET',
  },
  bigValueTransaction: {
    path: 'app/highest-invoice-transaction',
    method: 'GET',
  },
};

export default urls;
