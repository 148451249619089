import React from 'react';
import { authBackgroundColor } from '../../../constant/colors/background';
import clsx from 'clsx';
import { Pie } from 'react-chartjs-2';
import { Card } from '@mui/material';
import { selectDropdownBorderColor } from '../../../constant/colors/border';
import { ColumnDef } from '@tanstack/react-table';
import AlternateColorTable from '../../../component/tables/alternateColorsTableWithSelection';

type TransactionType = {
  customer: string;
  amount: string;
  time: string;
  ref: string;
  branch: string;
  type: string;
};

const Transaction = () => {
  const columns = React.useMemo<ColumnDef<TransactionType>[]>(
    () => [
      {
        accessorKey: 'customer',
        id: 'Customer',
        header: () => 'Customer',
        cell: ({ row }) => {
          return (
            <div>
              <div className='text-[12px]'>{row.original.customer}</div>
            </div>
          );
        },
      },
      {
        accessorKey: 'amount',
        id: 'Amount',
        header: () => 'Amount',
        cell: ({ row }) => {
          return (
            <div>
              <div className='text-[12px]'>{row.original.amount}</div>
            </div>
          );
        },
      },
      {
        accessorKey: 'time',
        id: 'Time',
        header: () => 'Time',
        cell: ({ row }) => {
          return (
            <div>
              <div className='text-[12px]'>{row.original.time}</div>
            </div>
          );
        },
      },

      {
        accessorKey: 'ref',
        id: 'Ref',
        header: () => 'Ref',
        cell: ({ row }) => {
          return (
            <div>
              <div className='text-[12px]'>{row.original.ref}</div>
            </div>
          );
        },
      },
      {
        accessorKey: 'branch',
        id: 'Branch',
        header: () => 'Branch',
        cell: ({ row }) => {
          return (
            <div>
              <div className='text-[12px]'>{row.original.branch}</div>
            </div>
          );
        },
      },
      {
        accessorKey: 'type',
        id: 'Type',
        header: () => 'Type',
        cell: ({ row }) => {
          return (
            <div>
              <div
                className={clsx('text-[12px]', {
                  'text-green-800': row.original.type === 'Payment',
                  'text-red-800': row.original.type === 'Refund',
                })}
              >
                {row.original.type}
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableData: TransactionType[] = [
    {
      customer: 'John Doe',
      amount: '10,000',
      time: '12:00',
      ref: '123456',
      branch: 'Lagos',
      type: 'Payment',
    },
    {
      customer: 'John Doe',
      amount: '10,000',
      time: '12:00',
      ref: '123456',
      branch: 'Lagos',
      type: 'Refund',
    },
    {
      customer: 'John Doe',
      amount: '10,000',
      time: '12:00',
      ref: '123456',
      branch: 'Lagos',
      type: 'Payment',
    },
    {
      customer: 'John Doe',
      amount: '10,000',
      time: '12:00',
      ref: '123456',
      branch: 'Lagos',
      type: 'Payment',
    },
  ];

  const data = {
    labels: ['Shopper', 'Commission'],
    datasets: [
      {
        label: 'Revenue',
        data: [9, 0.1],
        backgroundColor: ['rgba(102, 51, 151, 0.9)', 'rgba(231, 183, 15, 0.9)'],
        borderColor: ['rgba(255, 255, 255, 0.9)', 'rgba(255, 255, 255, 0.9)'],
      },
    ],
  };

  return (
    <div className='flex gap-2 flex-wrap'>
      <div className=' w-full lg:w-[70%]'>
        <Card className='px-3 py-5'>
          <div className='flex justify-between'>
            <div>
              <select
                className={clsx(
                  'border py-2 px-5 min-w-[140px] rounded-sm',
                  selectDropdownBorderColor
                )}
              >
                <option>All Transaction</option>
                <option>Select 1</option>
                <option>Select 2</option>
              </select>
            </div>

            <div>
              <select
                className={clsx(
                  'border py-2 px-5 w-[140px] rounded-sm',
                  selectDropdownBorderColor
                )}
              >
                <option>Date</option>
                <option>Select 1</option>
                <option>Select 2</option>
              </select>
            </div>
          </div>
          <hr className='my-2' />
          <div>
            <AlternateColorTable columns={columns} row={tableData} />
          </div>
        </Card>
      </div>
      <div className=' w-full lg:w-[25%]'>
        <div
          className={clsx(
            'flex justify-between py-5 px-3 my-2 text-white rounded-[5px]',
            authBackgroundColor
          )}
        >
          <div>
            <div className='text-[12px]'>Total Sales Volume</div>
            <div className='text-[20px]'>10,345</div>
          </div>
          <div className='h-[50px] border-l-0 border border-white' />
          <div>
            <div className='text-[12px]'>Total Refund</div>
            <div className='text-[20px]'>10</div>
          </div>
        </div>

        <div className='my-2'>
          <Card className='py-4 px-2'>
            <div>
              <div className='font-bold'>Revenue Distribution</div>
              <hr className='my-2' />

              <div>
                <Pie data={data} className='w-[90%]' />
              </div>

              <div className='flex justify-between my-2'>
                <div
                  className={clsx(
                    'p-3 rounded-[3px] min-w-[45%] ',
                    authBackgroundColor
                  )}
                >
                  <div className='text-[9px] text-white'>Total Sales</div>
                  <div className='text-[16px] text-bold text-white'>
                    1,521,700
                  </div>
                </div>

                <div className=' min-w-[45%]  p-3 rounded-[3px] bg-[#e7b70fe6]'>
                  <div className='text-[9px] text-white'>Refund</div>
                  <div className='text-[16px] text-bold text-white'>
                    1,521,700
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
