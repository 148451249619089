import './alternateColorsTable.css';

import React, { HTMLProps } from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { tableHeaderBackgroundColor3 } from '../../../constant/colors/background';

const AlternateColorTable = ({ columns, row }: { columns: any; row?: any }) => {
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data: row,
    columns,
    state: {
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  return (
    <div>
      <table
        className='w-[95%] rounded-3xl overflow-x-scroll
      border-separate border-spacing-y-1 alternate-color-table
      '
      >
        <thead
          className={clsx(
            '  border-none text-[12px] text-white shadow-md text-left',
            tableHeaderBackgroundColor3
          )}
        >
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className='border-none font-semibold capitalize py-3'
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className='text-[15px] text-[#5A5555] alternate-color-table-body'>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td key={cell.id} className='py-2'>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AlternateColorTable;
