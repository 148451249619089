import {
  Outlet,
  useLocation,
  useNavigate,
  useRevalidator,
} from 'react-router-dom';
import {
  authBackgroundColor,
  sidebarBackgroundColor,
} from '../../constant/colors/background';

import { AiOutlineHome } from 'react-icons/ai';
import { GrTransaction } from 'react-icons/gr';
import { BsPeople } from 'react-icons/bs';
import { BsShopWindow } from 'react-icons/bs';
import { BiBarChartSquare } from 'react-icons/bi';
import { CiSettings } from 'react-icons/ci';

import { useEffect, useState } from 'react';
import { navBarText } from '../../constant/colors/text';
import clsx from 'clsx';
import { primaryIconColor } from '../../constant/icons';
import { IoIosArrowForward, IoMdArrowForward } from 'react-icons/io';
import logo from '../../assets/logo.jpeg';
import { useDispatch, useSelector } from 'react-redux';
import { stat } from 'fs';
import { RootState } from '../../store';
import { logoutUser } from '../../store/userSlice';
import { adminRole, supportRole } from '../../constant/roles';
import { FaRegCircleUser } from 'react-icons/fa6';

const NavItems = ({
  icon,
  title,
  path,
  options,
  active,
}: {
  icon: any;
  title: string;
  path: string;
  options?: {
    title: string;
    redirect: string;
    active: string[];
    access: string[];
  }[];
  active: string[];
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const userRole = useSelector(
    (state: RootState) => state.user?.userData?.role
  );

  const userData = useSelector((state: RootState) => state.user?.userData);

  const handleMouseEnter = () => setIsSubmenuOpen(true);
  const handleMouseLeave = () => setIsSubmenuOpen(false);

  const handleRedirect = () => {
    if (options) {
    } else {
      navigate(path);
    }
  };

  useEffect(() => {
    if (active.includes(location.pathname.toLocaleLowerCase())) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  }, [location]);

  return (
    <div
      className='m-1 relative'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={clsx(
          'my-10 flex items-center justify-center flex-col cursor-pointer',
          {
            [navBarText]: !isActive,
            'text-black': isActive,
          }
        )}
        onClick={handleRedirect}
      >
        {icon}

        <p className={clsx(['mt-2 text-[12px]'])}>{title}</p>
      </div>

      {isSubmenuOpen && (
        <div
          className={clsx(
            'w-[150px] absolute -top-1 left-[100px] rounded-r-[12px]',
            sidebarBackgroundColor
          )}
        >
          {options?.map((item) => {
            return item?.access &&
              item.access.some((role) => userRole.includes(role)) ? (
              <div
                onClick={() => navigate(item.redirect)}
                className={clsx(
                  'px-2 py-1 font-semibold cursor-pointer flex items-center justify-between text-[12px]',
                  {
                    [navBarText]: !item.active.includes(
                      location.pathname.toLocaleLowerCase()
                    ),
                    'text-black': item.active.includes(
                      location.pathname.toLocaleLowerCase()
                    ),
                  }
                )}
              >
                <div> {item.title}</div>
                <IoIosArrowForward />
              </div>
            ) : (
              <div></div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [roleBasedNavigation, setRoleBasedNavigation] = useState<any>([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state: RootState) => state.user);
  const userRole = useSelector(
    (state: RootState) => state.user?.userData?.role
  );

  const navItems = [
    {
      id: 1,
      icon: <AiOutlineHome size={30} />,
      title: 'Home',
      redirect: '/',
      active: ['/'],
      access: [adminRole],
    },
    {
      id: 2,
      icon: <GrTransaction size={30} />,
      title: 'Exchange',
      redirect: '/transaction',
      active: ['/transaction', '/sales'],
      access: [adminRole, supportRole],
      options: [
        {
          title: 'Transaction',
          redirect: '/transaction',
          active: ['/transaction'],
          access: [adminRole, supportRole],
        },
        {
          title: 'Sales',
          redirect: '/sales',
          active: ['/sales'],
          access: [adminRole],
        },
      ],
    },
    {
      id: 3,
      icon: <BsPeople size={30} />,
      title: 'Customer',
      redirect: '/customer',
      active: ['/customer', '/payment'],
      access: [adminRole, supportRole],
    },
    {
      id: 4,
      icon: <BsShopWindow size={30} />,
      title: 'Vendor',
      redirect: '/vendor',
      active: ['/vendor'],
      access: [adminRole, supportRole],
    },
    {
      id: 5,
      icon: <BiBarChartSquare size={30} />,
      title: 'Analytics',
      redirect: '/analytics',
      active: ['/analytics'],
      access: [adminRole],
    },
  ];

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  const handleHomePage = () => {
    if (userRole) {
      if (userRole.includes(adminRole)) {
        navigate('/');
      } else if (userRole.includes(supportRole)) {
        navigate('/transaction');
      } else {
        dispatch(logoutUser());
        navigate('/login');
      }
    } else {
      dispatch(logoutUser());
      navigate('/login');
    }
  };

  useEffect(() => {
    if (!userData.token) {
      navigate('/login');
    }

    if (userData?.refreshExpiry) {
      var currentDate = new Date();
      var targetDate = new Date(userData.refreshExpiry);

      if (currentDate > targetDate) {
        navigate('/login');
      }

      if (userRole) {
        const filteredNavItems = navItems.filter((item) => {
          return item.access.some((role) => userRole.includes(role));
        });
        setRoleBasedNavigation(filteredNavItems);
      }
    } else {
      navigate('/login');
    }
  }, [userData]);

  return (
    <div>
      <div className='w-full bg-black h-[65px] text-white px-10 flex items-center justify-between z-20'>
        <img
          src={logo}
          className='w-[50px] h-[50px] cursor-pointer'
          onClick={() => {
            handleHomePage();
          }}
        />

        <div
          className='relative'
          onMouseEnter={handleOpen}
          onMouseLeave={handleClose}
        >
          {/* <img
            className='w-[50px] h-[50px] rounded-full cursor-pointer'
            src='https://placehold.co/400'
          /> */}

          <FaRegCircleUser className='text-[50px]' />

          {isOpen && (
            <div
              className={clsx(
                'absolute w-[210px] px-4 py-5  top-[50px] -left-[150px] rounded-[12px] z-20',
                authBackgroundColor
              )}
            >
              <div className='text-[16px] font-bold'>
                {userData?.userData?.email}
              </div>
              <hr className='my-1' />

              <div className='text-[12px]'>
                <div className='flex justify-between cursor-pointer font-bold my-2 items-center'>
                  Change Password <IoIosArrowForward />
                </div>

                <div
                  className='flex justify-between cursor-pointer font-bold my-2 items-center'
                  onClick={handleLogout}
                >
                  Logout <IoMdArrowForward />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='flex'>
        <div className=' w-[130px]  mr-10  mt-2 justify-center hidden md:block'>
          {roleBasedNavigation && roleBasedNavigation.length > 0 && (
            <div
              className={clsx(
                'border w-[111px] rounded-[10px] min-h-20 shadow-md',
                {
                  [sidebarBackgroundColor]: true,
                }
              )}
            >
              {roleBasedNavigation.map((item: any) => (
                <NavItems
                  key={item.title}
                  icon={item.icon}
                  title={item.title}
                  path={item.redirect}
                  options={item?.options}
                  active={item.active || []}
                />
              ))}
            </div>
          )}
        </div>

        <div className='w-full'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
