import React from 'react';
import { authBackgroundColor } from '../../../constant/colors/background';
import clsx from 'clsx';
import { Pie } from 'react-chartjs-2';
import { Card } from '@mui/material';
import { selectDropdownBorderColor } from '../../../constant/colors/border';
import { ColumnDef } from '@tanstack/react-table';
import AlternateColorTable from '../../../component/tables/alternateColorsTableWithSelection';
import UserCard from '../../../component/userCard';

import { FiPhone } from 'react-icons/fi';
import { BsEnvelopeArrowUpFill } from 'react-icons/bs';
import { MdLocationPin } from 'react-icons/md';
import {
  submitButton,
  submitButtonRed,
} from '../../../constant/colors/buttons';

const Branches = () => {
  return (
    <div className='flex gap-2 flex-wrap'>
      <div className=' w-full lg:w-[70%]'>
        <Card className='px-3 py-5'>
          <div className='flex justify-between'>
            <div>
              <div className='text-[20px] font-semibold'>All Branches</div>
            </div>

            <div>
              <input
                className={clsx(
                  'border py-2 px-5 w-[250px] rounded-sm',
                  selectDropdownBorderColor
                )}
                type='text'
                placeholder='Search'
              />
            </div>
          </div>
          <hr className='my-2' />

          <div className='flex gap-2 justify-between'>
            <div className='w-[50%]'>
              <UserCard isActive={false} />
              <UserCard isActive={true} />
              <UserCard isActive={false} />
              <UserCard isActive={false} />
              <UserCard isActive={false} />
            </div>
            <div className='w-[45%]'>
              <Card className='py-10 px-5'>
                <div>
                  <div>
                    <div className='text-[18px] font-semibold'>
                      Mikocheni Branch{' '}
                    </div>
                    <div className='text-[12px] font-semibold text-gray-500'>
                      Asha Rashidi{' '}
                    </div>
                    <div className='text-[12px] font-semibold text-gray-500'>
                      346420{' '}
                    </div>
                  </div>
                </div>

                <hr className='my-3' />

                <div>
                  <div>
                    <div className='text-[18px] font-semibold'>Contact</div>
                    <div className='text-[12px] font-semibold text-gray-500  flex gap-1 my-1'>
                      <FiPhone /> +255 647 343 333
                    </div>
                    <div className='text-[12px] font-semibold text-gray-500  flex gap-1 my-1'>
                      <BsEnvelopeArrowUpFill /> mkocheni@shopper.co.tz
                    </div>
                    <div className='text-[12px] font-semibold text-gray-500 flex gap-1 my-1'>
                      <MdLocationPin /> Mikocheni A, Old Bagamoyo Road, Dar es
                      salaam
                    </div>
                  </div>

                  <div className='w-full h-[200px] my-10 '>
                    <img
                      className='w-full h-full object-cover rounded-[10px]'
                      src='https://placehold.co/600x400'
                      alt='menu'
                    />
                  </div>

                  <hr />

                  <div className='my-5 flex justify-between'>
                    <div>
                      <div className='text-[10px]'>Created By</div>
                      <div className='text-[12px] font-semibold'>
                        Jumanne rashidi
                      </div>
                    </div>

                    <div>
                      <div className='text-[10px]'>Previous state</div>
                      <div className='text-[12px] font-semibold'>Null</div>
                    </div>
                  </div>

                  <div>
                    <div className='text-[10px]'>Date</div>
                    <div className='text-[12px] font-semibold'>
                      23 May 2020 | 08:23:23
                    </div>
                  </div>
                </div>
              </Card>

              <div className='my-5 flex justify-between'>
                <div
                  className={clsx(
                    'w-[200px] text-white h-[50px] border flex items-center justify-center rounded-[5px] cursor-pointer',
                    submitButtonRed
                  )}
                >
                  Suspend
                </div>

                <div
                  className={clsx(
                    'w-[200px] h-[50px] text-white border flex items-center justify-center rounded-[5px] cursor-pointer',
                    submitButton
                  )}
                >
                  View Transaction
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className=' w-full lg:w-[25%]'>
        <div
          className={clsx(
            'flex justify-between py-5 px-3 my-2 text-white rounded-[5px]',
            authBackgroundColor
          )}
        >
          <div>
            <div className='text-[12px]'>Lifetime Sales</div>
            <div className='text-[20px]'>500</div>
          </div>
          <div className='h-[50px] border-l-0 border border-white' />
          <div>
            <div className='text-[12px]'>Lifetime Revenue</div>
            <div className='text-[20px]'>TZS 9,434,000</div>
          </div>
        </div>

        <div className='my-2'>
          <Card className='py-4 px-2'>
            <div>
              <div>
                <div className='text-[12px] text-gray-500 font-semibold'>
                  Evg Sale Volume/Day
                </div>
                <div className='font-bold mt-2 text-[18px] '>5 Transaction</div>
              </div>
              <hr className='my-5' />

              <div>
                <div className='text-[12px] text-gray-500 font-semibold'>
                  Evg Revenue/Day
                </div>
                <div className='font-bold text-[18px] mt-2'>TZS 18,868.00</div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Branches;
