import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface DateState {
  token: string;
  refreshToken: string;
  refreshExpiry: string;
  userData: any;
}

const initialState: DateState = {
  token: '',
  refreshToken: '',
  refreshExpiry: '',
  userData: {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authSuccessful: (
      state,
      action: PayloadAction<{
        token: string;
        refreshToken: string;
        refreshExpiry: string;
        userData: {};
      }>
    ) => {
      state.refreshToken = action.payload.refreshToken;
      state.refreshExpiry = action.payload.refreshExpiry;
      state.token = action.payload.token;
      state.userData = action.payload.userData;
    },

    logoutUser: (state) => {
      state.refreshExpiry = '';
      state.refreshToken = '';
      state.token = '';
      state.userData = {};
    },
  },
});

export const { authSuccessful, logoutUser } = userSlice.actions;

export default userSlice.reducer;

export {};
