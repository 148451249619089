import React, { useState } from 'react';
import VendorHeader from '../vendorHeader';
import EditName from './editName';
import BusinessType from './businessType';
import RegistrationNumber from './registrationNumber';
import TilNumber from './tilNumber';
import EditCategory from './editCategory';

type BusinessProfileMenuCardProps = {
  title: string;
  data: string;
  index: number;
  onClick: (index: number) => void;
  currentActive: number;
};

const BusinessProfileMenuCard = ({
  title,
  data,
  index,
  onClick,
  currentActive,
}: BusinessProfileMenuCardProps) => {
  return (
    <div
      className='px-2 py-1 border m-1 rounded-[3px] cursor-pointer bg-blue-100'
      onClick={() => onClick(index)}
    >
      <div className='text-[12px]'>{title}</div>
      <div className='text-[18px] font-semibold'>{data}</div>
    </div>
  );
};

const BusinessProfile = () => {
  const [currentMenuActive, setCurrentMenuActive] = useState(0);

  const handleMenuClick = (id: number) => {
    setCurrentMenuActive(id);
  };

  const profileMenu = [
    { index: 1, title: 'Display Name', data: 'SShoppers Supermarket' },
    { index: 2, title: 'Business Name', data: 'SShoppers Supermarket' },
    { index: 3, title: 'Business Type', data: 'Limited Company' },
    { index: 4, title: 'Registration Number', data: '123 123 123' },
    { index: 5, title: 'TIN Number', data: '123 123 123' },
  ];

  return (
    <div className='ml-3'>
      <VendorHeader title='Business Profile' />

      <div className='mt-4'>
        <img
          src='https://placehold.co/400'
          className='w-[100px] h-[100px] rounded-[30px]'
        />

        <div className='flex gap-2 my-2'>
          <div className='w-[48%]'>
            {profileMenu.map((menu) => (
              <BusinessProfileMenuCard
                key={menu.index}
                title={menu.title}
                data={menu.data}
                index={menu.index}
                onClick={handleMenuClick}
                currentActive={currentMenuActive}
              />
            ))}

            <div
              className='px-2 py-1 border m-1 rounded-[3px] cursor-pointer bg-blue-100'
              onClick={() => setCurrentMenuActive(6)}
            >
              <p className='text-[12px] font-bold'>Categories</p>

              <div className='my-5  flex overflow-scroll'>
                <div className=' shrink-0	 w-[80px] h-[80px] m-2 flex flex-col items-center border'>
                  <img
                    src='https://placehold.co/400'
                    className='h-14 w-14 rounded-full'
                  />
                  <p className='text-[10px] font-semibold text-center mt-2'>
                    Cate 1
                  </p>
                </div>
              </div>
            </div>

            <div
              className='px-2 py-1 border m-1 rounded-[3px]  bg-blue-100'
              onClick={() => setCurrentMenuActive(7)}
            >
              <p className='text-[12px] font-bold'>Attachments</p>

              <div className='my-5  flex overflow-scroll'>
                <div className=' shrink-0	 w-[85px] min-h-[100px] m-2 flex flex-col items-center border border-black p-1 rounded-[3px] cursor-pointer'>
                  <img
                    src='https://placehold.co/400'
                    className='h-[100px] w-full object-cover'
                  />
                  <p className='text-[10px] font-semibold text-center mt-2'>
                    Document 1
                  </p>
                </div>

                <div className=' shrink-0	 w-[85px] min-h-[100px] m-2 flex flex-col items-center border border-black p-1 rounded-[3px] cursor-pointer'>
                  <img
                    src='https://placehold.co/400'
                    className='h-[100px] w-full object-cover'
                  />
                  <p className='text-[10px] font-semibold text-center mt-2'>
                    Document 1
                  </p>
                </div>

                <div className=' shrink-0	 w-[85px] min-h-[100px] m-2 flex flex-col items-center border border-black p-1 rounded-[3px] cursor-pointer'>
                  <img
                    src='https://placehold.co/400'
                    className='h-[100px] w-full object-cover'
                  />
                  <p className='text-[10px] font-semibold text-center mt-2'>
                    Document 1
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-[48%]'>
            {currentMenuActive === 1 && <EditName />}
            {currentMenuActive === 3 && <BusinessType />}
            {currentMenuActive === 4 && <RegistrationNumber />}
            {currentMenuActive === 5 && <TilNumber />}
            {currentMenuActive === 6 && <EditCategory />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessProfile;
