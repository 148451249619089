import React, { useEffect, useState } from 'react';
import SearchBar from '../../component/Searchbar';
import BasicTableWithSelection from '../../component/tables/basicTableWithSelection';
import SecondarySearchHeader from '../../component/secondaryHeader';
import { ColumnDef } from '@tanstack/react-table';
import { IoMdOptions } from 'react-icons/io';
import clsx from 'clsx';
import { Card } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import apiRequest from '../../utils/apiRequest';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { stat } from 'fs';
import { RootState } from '../../store';
import toast from 'react-hot-toast';
import { selectDropdownBorderColor } from '../../constant/colors/border';
import AccessControl from '../../component/AccessControl';
import { adminRole, supportRole } from '../../constant/roles';

type VendorColumnsTypes = {
  name: string;
  address: string;
  phone: string;
  status: string;
};

interface StatusCounts {
  active: number;
  suspended: number;
  disabled: number;
}

interface VendorDataFormatted {
  name?: string;
  address?: string;
  phone?: string;
  status?: string;
  username?: string;
  id?: string;
}

const Vendors = () => {
  const [vendorData, setVendorData] = useState<VendorDataFormatted[]>([]);

  const [vendorDataFiltered, setVendorDataFiltered] = useState<
    VendorDataFormatted[]
  >([]);

  const [searchTerm, setSearchTerm] = useState<string | undefined | null>('');
  const [graphData, setGraphData] = useState<number[]>([0, 0, 0]);

  const vendorColumn = React.useMemo<ColumnDef<VendorColumnsTypes>[]>(
    () => [
      {
        accessorKey: 'name',
        id: 'name',
      },

      {
        id: 'address',
        accessorKey: 'address',
      },
      {
        id: 'phone',
        accessorKey: 'phone',
      },
      {
        id: 'status',
        accessorKey: 'status',
        cell: ({ row }) => {
          return (
            <div>
              <select
                disabled
                className={clsx('w-[100px] p-3 rounded-[3px]', {
                  'bg-[#2db57366]':
                    row.original.status.toLowerCase() === 'active',
                  'bg-[#e7b80f64]':
                    row.original.status.toLowerCase() === 'suspended',
                  'bg-[#9903036f]':
                    row.original.status.toLowerCase() === 'disabled',
                })}
                value={row.original.status}
              >
                <option>Select</option>
                <option value={'Active'}>Active</option>
                <option value={'Suspended'}>Suspended</option>
                <option value={'Disabled'}>Disable</option>
              </select>
            </div>
          );
        },
      },
    ],
    []
  );

  const getVendorList = async () => {
    const res = await apiRequest('getVendorList');

    if (!res.data) {
      toast.error('failed to get vendor list');
      return;
    }

    const initialGraph: StatusCounts = {
      active: 0,
      suspended: 0,
      disabled: 0,
    };

    const tempData = res.data.map((val: any) => {
      const temp = {
        name: `${val?.firstName} ${val?.lastName}`,
        address: val?.location,
        status: val?.status,
        phone: val?.phoneNumber,
        username: val?.userName,
        id: val?.id,
      };

      if (val?.status.toLowerCase()) {
        if (val?.status.toLowerCase() === 'active') {
          initialGraph.active += 1;
        } else if (val?.status.toLowerCase() === 'suspended') {
          initialGraph.suspended += 1;
        } else if (val?.status.toLowerCase() === 'disabled') {
          initialGraph.disabled += 1;
        }
      }
      return temp;
    });

    setVendorData(tempData);
    setGraphData([
      initialGraph.active,
      initialGraph.suspended,
      initialGraph.disabled,
    ]);
  };

  const searchUsers = (search: string) => {
    const searchTerm = search.toLowerCase();
    const filteredCustomer = vendorData.filter((vendor) => {
      if (!vendor?.name || !vendor?.id || !vendor?.username) {
        return;
      }
      return (
        vendor?.name.toLowerCase().includes(searchTerm) ||
        vendor?.id.toLowerCase().includes(searchTerm) ||
        vendor?.username.toLowerCase().includes(searchTerm)
      );
    });

    setSearchTerm(search);
    setVendorDataFiltered(filteredCustomer);
  };

  useEffect(() => {
    getVendorList();
  }, []);

  return (
    <AccessControl accessibleBy={[adminRole, supportRole]}>
      <div>
        <SearchBar />

        <div className='float-right'>
          <input
            type='text'
            className={clsx(
              'border m-3 w-[300px] md:w-[200px] p-2 rounded-[3px]',
              selectDropdownBorderColor
            )}
            placeholder='Search Vendor'
            onChange={(e) => searchUsers(e.target.value)}
          />
        </div>
        <SecondarySearchHeader title='Vendors' />

        <div className='flex flex-wrap'>
          <div className=' m-2 w-full lg:w-[65%]'>
            <BasicTableWithSelection
              columns={vendorColumn}
              row={searchTerm ? vendorDataFiltered : vendorData}
            />
          </div>

          <div className='m-2 w-full lg:w-[30%]'>
            <Card className='w-full px-3 py-10'>
              <div className='flex justify-between'>
                {/* <div>
                <p className='text-[20px] font-semibold '>Today</p>
                <p className='text-[10px] text-[#565656]'>March 19, 2021</p>
              </div> */}

                <div>
                  <p className='text-[20px] font-semibold '>Vendors</p>
                  {/* <p className='text-[10px] text-[#565656]'>All 19, 2021</p> */}
                </div>
              </div>

              <hr className='my-2' />

              <div>
                <p className='text-[10px] text-[#565656]'>Total Vendors</p>
                <p className='text-[20px] font-semibold '>
                  {vendorData?.length || 0}
                </p>
              </div>

              <div>
                <Pie
                  data={{
                    labels: ['Active', 'Suspended', 'Disabled'],
                    datasets: [
                      {
                        label: 'Accounts',
                        data: graphData,
                        backgroundColor: [
                          'rgba(21, 116, 70, 0.6)',
                          'rgba(231, 183, 15, 0.6)',
                          'rgba(153, 3, 3, 0.6)',
                        ],
                        borderColor: [
                          'rgba(21, 116, 70, 0.4)',
                          'rgba(231, 183, 15, 0.4)',
                          'rgba(153, 3, 3, 0.4)',
                        ],
                      },
                    ],
                  }}
                  className='w-[90%]'
                />
              </div>

              <div className='my-2 flex justify-center'>
                <div className='m-2  w-[100px] h-[56px] rounded-[5px] bg-green-200 flex items-center justify-center flex-col'>
                  <div>
                    <div className='text-[8px]'> Active Vendors</div>
                    <div className='text-green-700 font-semibold'>
                      {graphData[0]}
                    </div>
                  </div>
                </div>

                <div className='m-2 w-[100px] h-[56px] rounded-[5px] bg-yellow-200 flex items-center justify-center flex-col'>
                  <div>
                    <div className='text-[8px]'> Suspended Vendors</div>
                    <div className='text-yellow-700 font-semibold'>
                      {graphData[1]}
                    </div>
                  </div>
                </div>

                <div className='m-2  w-[100px] h-[56px] rounded-[5px] bg-red-200 flex items-center justify-center flex-col'>
                  <div>
                    <div className='text-[8px]'> Disabled Vendors</div>
                    <div className='text-red-700 font-semibold'>
                      {' '}
                      {graphData[2]}
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </Card>
          </div>
        </div>
      </div>
    </AccessControl>
  );
};

export default Vendors;
