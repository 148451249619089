import clsx from 'clsx';
import React, { useState } from 'react';
import { selectDropdownBorderColor } from '../../../../../constant/colors/border';
import { submitButton } from '../../../../../constant/colors/buttons';

const BusinessType = () => {
  const [selectedType, setSelectedType] = useState(1);

  const handleSelect = (index: number) => {
    setSelectedType(index);
  };

  const businessType = [
    { index: 1, name: 'Government Institute' },
    { index: 2, name: 'Public Company' },
    { index: 3, name: 'Sole Proprietor' },
    { index: 4, name: 'Partnership' },
    { index: 5, name: 'NGO' },
    { index: 6, name: 'Limited Company' },
  ];

  return (
    <div>
      <div className='font-semibold text-[14px]'>Select Business Type</div>

      <div className='flex flex-wrap gap-2 mt-5 '>
        {businessType.map((val) => {
          return (
            <span
              onClick={() => handleSelect(val.index)}
              className={clsx(
                'border px-3 py-1 rounded-[3px] cursor-pointer text-[12px]',
                selectDropdownBorderColor,
                {
                  'bg-[#663397] text-white': selectedType === val.index,
                }
              )}
            >
              {val.name}
            </span>
          );
        })}
      </div>

      <div
        className={clsx(
          'px-3 py-2 flex w-[100px] items-center justify-center rounded-[3px] cursor-pointer mt-2 text-white float-right',
          submitButton
        )}
      >
        Submit
      </div>
    </div>
  );
};

export default BusinessType;
