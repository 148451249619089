import React, { useEffect, useState } from 'react';

import { PiBagSimpleFill } from 'react-icons/pi';
import { HiUserGroup } from 'react-icons/hi2';
import { HiHome } from 'react-icons/hi';
import { LuArrowDownUp } from 'react-icons/lu';
import clsx from 'clsx';
import { authBackgroundColor } from '../../../constant/colors/background';
import { FaCalendar } from 'react-icons/fa';
import BusinessProfile from '../components/businessProfile';
import { useSearchParams } from 'react-router-dom';
import VendorProfileMain from '../VendorProfileMain';
import Transaction from '../transaction';
import Branches from '../branches';
import Staff from '../staff';

const VendorMenuCard = ({
  icon,
  title,
  id,
  currentActive,
  handleClick,
}: {
  icon: React.ReactNode;
  title: string;
  id: number;
  currentActive: number;
  handleClick: (id: number) => void;
}) => {
  return (
    <div
      className='min-w-[100px] flex items-center gap-1 cursor-pointer text-white'
      onClick={() => handleClick(id)}
    >
      {icon}
      <div>
        <div className='text-[12px] font-semibold'>{title}</div>
        {currentActive === id && <div className='border-[1px] border-t-0' />}
      </div>
    </div>
  );
};

const VendorProfileBase = () => {
  const [currentActive, setCurrentActive] = useState<number>(1);

  const [searchParams, setSearchParams] = useSearchParams();

  const profileMenu = [
    { id: 1, name: 'Profile', icon: <PiBagSimpleFill size={30} /> },
    { id: 2, name: 'Staffs', icon: <HiUserGroup size={30} /> },
    { id: 3, name: 'Branches', icon: <HiHome size={30} /> },
    { id: 4, name: 'Transaction', icon: <LuArrowDownUp size={30} /> },
  ];

  const handleMenuClick = (id: number) => {
    setCurrentActive(id);
    setSearchParams({ menu: id.toString() });
  };

  useEffect(() => {
    const currentMenu = searchParams.get('menu');
    if (currentMenu) {
      setCurrentActive(Number(currentMenu));
    } else setCurrentActive(1);
  }, []);

  return (
    <div>
      <div className='my-5 flex items-center justify-between pr-5'>
        <div className='flex gap-3 items-center'>
          <img
            src='https://placehold.co/400'
            className='w-[100px] h-[100px] rounded-[30px]'
          />

          <p className='text-xl font-bold w-[130px]'>Shoppers Supermarket</p>

          <div
            className={clsx(
              'px-3 py-5 rounded-[5px] flex gap-2',
              authBackgroundColor
            )}
          >
            {profileMenu.map((menu) => {
              return (
                <VendorMenuCard
                  id={menu.id}
                  icon={menu.icon}
                  title={menu.name}
                  currentActive={currentActive}
                  handleClick={handleMenuClick}
                />
              );
            })}
          </div>
        </div>

        <div>
          <div className='text-xs font-semibold'>Trading Period</div>
          <div className='flex gap-2 items-center text-2xl font-semibold'>
            {' '}
            <FaCalendar /> 204 Days
          </div>
        </div>
      </div>

      <div>{currentActive === 1 && <VendorProfileMain />}</div>
      <div>{currentActive === 2 && <Staff />}</div>
      <div>{currentActive === 3 && <Branches />}</div>
      <div>{currentActive === 4 && <Transaction />}</div>
    </div>
  );
};

export default VendorProfileBase;
