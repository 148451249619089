import React, { useEffect, useState } from 'react';
import SearchBar from '../../component/Searchbar';
import BasicTable from '../../component/tables/basicTableWithSelection';
import { ColumnDef } from '@tanstack/react-table';

import { BiCheckDouble, BiTime } from 'react-icons/bi';
import { PiWarningCircle } from 'react-icons/pi';
import { TbAlertTriangleOff } from 'react-icons/tb';
import { LuUndo2 } from 'react-icons/lu';
import downloadIcon from '../../assets/download-icon.png';
import { RxCross1 } from 'react-icons/rx';
import arrow from '../../assets/arrow.svg';

import Card from '../../component/Card';
import clsx from 'clsx';
import {
  cardDetailsSecondaryText,
  cardDetailsText,
} from '../../constant/colors/text';
import SecondarySearchHeader from '../../component/secondaryHeader';
import apiRequest from '../../utils/apiRequest';
import { fromDateData, toDateData } from '../../store/dateSlice';
import toast from 'react-hot-toast';
import moment from 'moment';
import dateFormat from '../../constant/dateFormat';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import AccessControl from '../../component/AccessControl';
import { adminRole, supportRole } from '../../constant/roles';

type Transaction = {
  id?: number;
  from?: string;
  fromProfile?: string;
  to?: string;
  points?: string;
  amount?: string;
  reference?: string;
  date?: string;
  time?: string;
  status?: string;
};

const Transaction = () => {
  const [transactionDataList, setTransactionDataList] = useState<
    Transaction[] | null | undefined
  >();

  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const [transactionFilterData, setTransactionFilterData] = useState<
    Transaction[] | null | undefined
  >([]);

  const [selectedRow, setSelectedRow] = useState<null | number>();

  const [transactionDetailsById, setTransactionDetailsById] =
    useState<null | Transaction>();

  const columns = React.useMemo<ColumnDef<Transaction>[]>(
    () => [
      {
        accessorKey: 'from',
        header: () => 'From',
        id: 'from',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.from}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.to,
        header: () => 'To',
        id: 'to',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.to}
            </div>
          );
        },
      },
      {
        accessorKey: 'point',
        header: () => 'Tokens',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.points}
            </div>
          );
        },
      },
      {
        accessorKey: 'amount',
        header: () => 'Amount',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.amount}
            </div>
          );
        },
      },
      {
        accessorKey: 'reference',
        header: () => 'Reference',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.reference}
            </div>
          );
        },
      },

      {
        accessorKey: 'date',
        header: () => 'Date',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointe'
            >
              {row.original?.date}
            </div>
          );
        },
      },

      {
        accessorKey: 'time',
        header: () => 'Time',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointe'
            >
              {row.original?.time}
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        cell: ({ row }) => {
          const renderIcon = (iconName: string | undefined) => {
            switch (iconName?.toLowerCase()) {
              case 'approved':
                return <BiCheckDouble size={30} className='text-green-800' />;
              case 'pending':
                return (
                  <PiWarningCircle size={30} className='text-yellow-500' />
                );
              case 'failed':
                return (
                  <TbAlertTriangleOff size={30} className='text-red-500' />
                );
              case 'reversed':
                return <LuUndo2 size={30} />;

              default:
                return;
            }
          };

          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {renderIcon(row.original?.status)}
            </div>
          );
        },
      },
    ],
    []
  );

  const columnsSelected = React.useMemo<ColumnDef<Transaction>[]>(
    () => [
      {
        accessorKey: 'from',
        id: 'from',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.from}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.to,
        id: 'to',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.to}
            </div>
          );
        },
      },
      {
        accessorKey: 'amount',
        header: () => 'Amount',
        cell: ({ row }) => {
          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.amount}
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header: () => 'status',
        cell: ({ row }) => {
          const renderIcon = (iconName: string | undefined) => {
            switch (iconName?.toLowerCase()) {
              case 'approved':
                return <BiCheckDouble size={30} className='text-green-800' />;
              case 'pending':
                return (
                  <PiWarningCircle size={30} className='text-yellow-500' />
                );
              case 'failed':
                return (
                  <TbAlertTriangleOff size={30} className='text-red-500' />
                );
              case 'reversed':
                return <LuUndo2 size={30} />;

              default:
                return;
            }
          };

          return (
            <div
              onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {renderIcon(row.original?.status)}
            </div>
          );
        },
      },
    ],
    []
  );

  const fromDate = useSelector((state: RootState) => state.date.fromDate);
  const toDate = useSelector((state: RootState) => state.date.toDate);

  const handleUserClick = (row: Transaction) => {
    setSelectedRow(row.id);

    setTransactionDetailsById(row);
  };

  const getTransactionData = async () => {
    const params = {
      fromDate,
      toDate,
    };

    const res = await apiRequest('getAllTransaction', null, params);

    if (res.error) {
      toast.error('Failed to get data');
      setTransactionDataList([]);
      return;
    }

    const formattedData = res.data.map((data: any) => {
      return {
        id: data?.id,
        from: data?.customerName,
        to: data?.merchantName,
        points: data?.points,
        amount: data?.invoiceAmount,
        reference: data.invoiceId,
        status: data?.status,
        date: moment(data?.createdDt).format(dateFormat),
        time: moment(data?.createdDt).format('HH:mm:ss'),
      };
    });

    setTransactionDataList(formattedData);
  };

  const handleStatusFilter = (status: string) => {
    setStatusFilter(status);

    if (status) {
      if (searchTerm) {
        const filteredData = transactionFilterData?.filter(
          (data) => data.status === status
        );
        setTransactionFilterData(filteredData);
      } else {
        const filteredData = transactionDataList?.filter(
          (data) => data.status === status
        );
        setTransactionFilterData(filteredData);
      }
    }
  };

  const handleSearch = (search: string) => {
    const query = search.toLowerCase();

    setSearchTerm(query);

    if (query) {
      if (statusFilter) {
        const filteredData = transactionDataList?.filter((data) => {
          return (
            (data.from?.toLocaleLowerCase().includes(query) ||
              data.to?.toLocaleLowerCase().includes(query) ||
              data.reference?.toLocaleLowerCase().includes(query)) &&
            data.status === statusFilter
          );
        });
        setTransactionFilterData(filteredData);
      } else {
        const filteredData = transactionDataList?.filter((data) => {
          return (
            data.from?.toLocaleLowerCase().includes(query) ||
            data.to?.toLocaleLowerCase().includes(query) ||
            data.reference?.toLocaleLowerCase().includes(query)
          );
        });
        setTransactionFilterData(filteredData);
      }
    } else {
      if (statusFilter) {
        handleStatusFilter(statusFilter);
      }
    }
  };

  useEffect(() => {
    getTransactionData();
  }, [fromDate, toDate]);

  return (
    <AccessControl accessibleBy={[supportRole, adminRole]}>
      <div>
        <SearchBar />

        <div className='flex gap-3 float-right mr-20 mt-3'>
          <div>
            <input
              type='text'
              className='border border-gray-400 py-2 rounded-md px-1 w-[250px]'
              placeholder='Search by from, to or reference'
              onChange={(e) => handleSearch(e.target.value)}
            />
          </div>
          <div>
            <select
              className='border border-gray-400 py-2 rounded-md px-1 w-[250px]'
              onChange={(e) => handleStatusFilter(e.target.value)}
              value={statusFilter}
            >
              <option value=''>Status</option>
              <option value='APPROVED'>Successful</option>
              <option value='PENDING'>Pending</option>
            </select>
          </div>
        </div>
        <SecondarySearchHeader title='Transaction' />

        <div className='flex flex-wrap lg:flex-nowrap'>
          <div
            className={clsx({
              'w-full lg:w-[70%]': selectedRow,
              'w-full': !selectedRow,
            })}
          >
            {transactionDataList && (
              <BasicTable
                columns={selectedRow ? columnsSelected : columns}
                row={
                  statusFilter || searchTerm
                    ? transactionFilterData
                    : transactionDataList
                }
              />
            )}
          </div>

          {selectedRow && (
            <div className='w-full md:w-[330px]'>
              <Card>
                <div className='flex flex-row items-center justify-between cursor-pointer mb-5'>
                  {/* <img src={downloadIcon} 
                className='h-10 w-10' /> */}
                  <div />
                  <RxCross1
                    size={20}
                    onClick={() => setSelectedRow(null)}
                    className='cursor-pointer text-red-600'
                  />
                </div>

                <div>
                  <p className='text-[13px] font-semibold'>
                    Reference #: {transactionDetailsById?.reference}
                  </p>
                </div>
                <hr className='my-4' />

                <div>
                  <div className='flex items-center'>
                    <div className='flex flex-col items-center'>
                      {/* <img
                      className='h-20 w-20 rounded-full border-2 border-black p-[1px]'
                      src={transactionDetailsById?.formProfilePicture}
                    /> */}
                      <div>
                        <p className={clsx('text-[10px]', cardDetailsText)}>
                          {transactionDetailsById?.from}
                        </p>
                        <p
                          className={clsx(
                            'text-[8px] text-center',
                            cardDetailsSecondaryText
                          )}
                        >
                          Sender
                        </p>
                      </div>
                    </div>
                    <img src={arrow} className='-mt-1 m-1 w-[50%]' />
                    <div className='flex flex-col items-center'>
                      {/* <img
                      className='h-20 w-20 rounded-full border-2 border-black p-[1px]'
                      src={transactionDetailsById?.toProfilePicture}
                    /> */}
                      <div>
                        <p className={clsx('text-[10px]', cardDetailsText)}>
                          {transactionDetailsById?.to}
                        </p>
                        <p
                          className={clsx(
                            'text-[8px] text-center',
                            cardDetailsSecondaryText
                          )}
                        >
                          Receiver
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='my-5 text-center'>
                  <p className={clsx('text-[11px]', cardDetailsSecondaryText)}>
                    Token Sent
                  </p>
                  <p
                    className={clsx(
                      'text-[25px] font-bold',
                      cardDetailsSecondaryText
                    )}
                  >
                    {transactionDetailsById?.points}
                  </p>
                </div>

                <div className='my-5 text-center'>
                  <p className={clsx('text-[11px]', cardDetailsSecondaryText)}>
                    Equivalent to
                  </p>
                  <p className={clsx('text-[25px] font-bold')}>TZS</p>
                  <p className={clsx('text-[25px] font-bold')}>
                    {transactionDetailsById?.amount}
                  </p>
                </div>

                <div className='my-5 text-center'>
                  <p className={clsx('text-[11px]', cardDetailsSecondaryText)}>
                    Transaction Status
                  </p>
                  <span
                    className={clsx(
                      'text-[20px] flex items-center justify-center gap-2',
                      cardDetailsSecondaryText
                    )}
                  >
                    {transactionDetailsById?.status?.toLowerCase() ===
                    'approved' ? (
                      <div className='flex gap-2'>
                        <p>Successful</p>{' '}
                        <BiCheckDouble className='text-green-600' size={30} />
                      </div>
                    ) : (
                      <div className='flex gap-2'>
                        <p>Failed</p>{' '}
                        <BiTime className='text-red-600' size={30} />
                      </div>
                    )}
                  </span>
                </div>

                <div className='my-5 flex justify-between'>
                  <div>
                    <p
                      className={clsx('text-[11px]', cardDetailsSecondaryText)}
                    >
                      Date
                    </p>
                    <p className={clsx('text-[13px] font-semibold')}>
                      {transactionDetailsById?.date}
                    </p>
                  </div>

                  <div>
                    <p
                      className={clsx('text-[11px]', cardDetailsSecondaryText)}
                    >
                      Time
                    </p>
                    <p className={clsx('text-[13px] font-semibold')}>
                      {transactionDetailsById?.time}
                    </p>
                  </div>
                </div>

                {/* <p
                className={clsx(
                  'text-[11px] text-center cursor-pointer',
                  cardDetailsText
                )}
              >
                Reverse Transaction
              </p> */}
              </Card>
            </div>
          )}
        </div>
      </div>
    </AccessControl>
  );
};

export default Transaction;
