import React, { useEffect, useState } from 'react';
import SearchBar from '../../component/Searchbar';
import { Card } from '@mui/material';
import clsx from 'clsx';
import { cardHeaderBackgroundColor } from '../../constant/colors/background';
import { Line } from 'react-chartjs-2';
import { GrTransaction } from 'react-icons/gr';
import { BsPeople } from 'react-icons/bs';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { secondaryIconColor } from '../../constant/icons';
import AnalyticsCard from './analyticsCard';
import apiRequest from '../../utils/apiRequest';
import AccessControl from '../../component/AccessControl';
import { adminRole, supportRole } from '../../constant/roles';

type AnalyticsProps = {
  amount: string;
  name: string;
  up: boolean;
};

const Analytics = () => {
  const [selectedUserType, setSelectedUserType] = useState<
    'customer' | 'vendor'
  >('customer');
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);

  const [bigSpenders, setBigSpenders] = useState<AnalyticsProps[]>([]);
  const [bigSellers, setBigSellers] = useState<AnalyticsProps[]>([]);
  const [bigTransaction, setBigTransaction] = useState<[]>([]);

  const handleUserTypeChange = (type: 'customer' | 'vendor') => {
    setSelectedUserType(type);
    toggleUserDropdown(false);
  };

  const toggleUserDropdown = (isOpen: boolean) => {
    setIsUserDropdownOpen(isOpen);
  };

  const getBigSpenders = async () => {
    const res = await apiRequest('bigSpenders');

    if (!res.data) {
      setBigSpenders([]);
    } else {
      const formattedData = {
        amount: res?.data?.formattedTotalPurchase,
        name: `${res?.data?.firstName} ${res?.data?.lastName}`,
        up: false,
      };

      setBigSpenders([formattedData]);
    }
  };

  const getBigSellers = async () => {
    const res = await apiRequest('bigSellers');

    if (!res.data) {
      setBigSellers([]);
    } else {
      const formattedData = {
        amount: res?.data?.formattedTotalSales,
        name: res.data?.merchantName,
        up: false,
      };

      setBigSellers([formattedData]);
    }
  };

  const getBigTransaction = async () => {
    const res = await apiRequest('bigValueTransaction');

    if (!res.data) {
      setBigTransaction([]);
    } else {
      setBigTransaction(res.data);
    }
  };

  useEffect(() => {
    getBigSpenders();
    getBigSellers();
    getBigTransaction();
  }, []);

  return (
    <AccessControl accessibleBy={[adminRole]}>
      <div className='w-full min-h-screen p-2'>
        <SearchBar type='insights' title='Insights' />

        <div className='w-[95%] '>
          <Card className='p-3'>
            {/* <div className='p-4'>
            <p className='text-[25px] font-semibold'>15th, May 2023</p>
          </div> */}

            {/* <div className='flex justify-between w-full flex-wrap text-white'>
              <div className='w-full md:w-[45%] m-1'>
                <div
                  className={clsx(
                    'h-[64px] rounded-[5px] flex',
                    cardHeaderBackgroundColor
                  )}
                >
                  <GrTransaction
                    size={25}
                    color='white'
                    className='mt-auto mb-auto ml-5'
                  />
                </div>

                <Card className='p-4'>
                  <div className='my-2'>
                    <div className='text-black flex justify-between'>
                      <div>
                        <p className='text-[12px] font-semibold'>
                          Total Transactions
                        </p>
                        <p className='text-[20px] font-semibold'>3034</p>
                      </div>

                      <div className='text-right'>
                        <p className='text-[12px] font-semibold'>Total Value</p>
                        <p className='text-[20px] font-semibold'>
                          TZS 30,000,000
                        </p>
                      </div>
                    </div>
                  </div>
                  <Line
                    // datasetIdKey='1'
                    height={'100%'}
                    data={{
                      labels: ['Jun', 'Jul', 'Aug'],
                      datasets: [
                        {
                          // id: 1,
                          label: 'MD',
                          data: [5, 2, 7],
                          borderColor: '#36A2EB',
                          backgroundColor: '#9BD0F5',
                        },
                        {
                          // id: 2,
                          label: 'NIFTY',
                          data: [3, 8, 1],
                          borderColor: '#FF6384',
                          backgroundColor: '#FFB1C1',
                        },
                      ],
                    }}
                  />
                </Card>
              </div>

              <div className='w-full md:w-[45%] m-1'>
                <div
                  className={clsx(
                    'h-[64px] rounded-[5px] flex items-center gap-2 justify-between',
                    cardHeaderBackgroundColor
                  )}
                >
                  <div className='flex gap-2'>
                    <BsPeople
                      size={25}
                      color='white'
                      className='mt-auto mb-auto ml-5'
                    />

                    <p className='text-[16px] font-bold'>Users</p>
                  </div>

                  <div
                    className={clsx('mr-2 flex gap-2 items-center text-white')}
                  >
                    <div className='text-[10px] font-semibold'>
                      <p
                        onClick={() => handleUserTypeChange('customer')}
                        className={clsx('cursor-pointer ', {
                          'opacity-40': selectedUserType !== 'customer',
                          hidden: !isUserDropdownOpen,
                        })}
                      >
                        Customers
                      </p>
                      <p
                        onClick={() => handleUserTypeChange('vendor')}
                        className={clsx('cursor-pointer', {
                          'opacity-40': selectedUserType !== 'vendor',
                          hidden: !isUserDropdownOpen,
                        })}
                      >
                        Vendors
                      </p>

                      <p
                        onClick={() => toggleUserDropdown(!isUserDropdownOpen)}
                        className={clsx('cursor-pointer capitalize', {
                          hidden: isUserDropdownOpen,
                        })}
                      >
                        {selectedUserType}
                      </p>
                    </div>
                    <IoIosArrowDropdownCircle
                      onClick={() => toggleUserDropdown(!isUserDropdownOpen)}
                      className={clsx(
                        'cursor-pointer text-white',
                        secondaryIconColor,
                        {
                          'rotate-180': isUserDropdownOpen,
                        }
                      )}
                    />
                  </div>
                </div>

                <Card className='p-4'>
                  <div className='my-2'>
                    <div className='text-black flex justify-between'>
                      <div className=''>
                        <p className='text-[12px] font-semibold '>Users</p>
                        <p className='text-[20px] font-semibold'>3034</p>
                      </div>
                    </div>
                  </div>
                  <Line
                    // datasetIdKey='1'
                    height={'100%'}
                    data={{
                      labels: ['Jun', 'Jul', 'Aug'],
                      datasets: [
                        {
                          // id: 1,
                          label: 'MD',
                          data: [5, 2, 7],
                          borderColor: '#36A2EB',
                          backgroundColor: '#9BD0F5',
                        },
                        {
                          // id: 2,
                          label: 'NIFTY',
                          data: [3, 8, 1],
                          borderColor: '#FF6384',
                          backgroundColor: '#FFB1C1',
                        },
                      ],
                    }}
                  />
                </Card>
              </div>
            </div> */}

            <div className='mt-10 flex flex-row flex-wrap justify-evenly'>
              <AnalyticsCard
                title='Big Value Transactions'
                type='transaction'
              />
              <AnalyticsCard
                title='Big Spenders'
                type='spenders'
                data={bigSpenders}
              />
              <AnalyticsCard
                title='Big Sellers'
                type='sellers'
                data={bigSellers}
              />
              {/* <AnalyticsCard title='High Trending Areas' type='area' /> */}
            </div>
          </Card>
        </div>
      </div>
    </AccessControl>
  );
};

export default Analytics;
