import axios from 'axios';
import baseUrl from '../../constant/baseURL';
import requests from '../../constant/requestURL';
import { toast } from 'react-hot-toast';
import { store } from '../../store';
import { startLoading, stopLoading } from '../../store/loadingSlice';
import { logoutUser } from '../../store/userSlice';
// check if jwt token is expired
const validateToken = (token: string) => {
  if (token) {
    const decodedToken = JSON.parse(atob(token.split('.')[1]));

    const currentTimestamp = Date.now();

    if (decodedToken.exp * 1000 < currentTimestamp) {
      localStorage.clear();
      window.location.href = '/login';
      toast.error('Session Expired');
    }
  }
};

const getToken = () => {
  const token = store.getState().user.token;

  if (token) {
    validateToken(token);
  }

  return token;
};

const apiRequest = async (
  path: string,
  body?: object | null,
  params?: { [key: string]: string }
) => {
  let data;
  let error;
  let status;

  store.dispatch(startLoading());

  const headers = {
    Authorization: `Bearer ${getToken()}`,
    'Access-Control-Allow-Origin': true,
  };

  let requestPath = requests[path] ? requests[path].path : path;

  const method = requests[path]?.method;

  if (params) {
    const urlSearchParams = new URLSearchParams();

    for (const key in params) {
      urlSearchParams.append(key, params[key]);
    }

    requestPath = `${requestPath}?${urlSearchParams
      .toString()
      .replaceAll('%2F', '/')}`;
  }

  try {
    console.log(requests[path]?.method, 'MET METHOD');
    console.log(params, 'MET PARAMS');
    console.log(headers, 'MET HEADERS');
    console.log(baseUrl, 'MET BASEURL');
    console.log('....................................');

    const res = await axios.request({
      baseURL: baseUrl,
      url: requestPath,
      method,
      data: body,
      headers,
    });

    status = res.status;
    data = res.data;
  } catch (err: any) {
    error = err?.response?.data;
  }

  store.dispatch(stopLoading());

  return { data, error, status };
};

export default apiRequest;
