import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { headerBackgroundColors } from '../../constant/colors/background';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import {
  fromDateData,
  toDateData,
  selectedDateTypeData,
} from '../../store/dateSlice';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../store';
import dateFormat from '../../constant/dateFormat';

type SearchBarProps = {
  type?: string;
  title?: string;
};

const SearchBar = ({ type, title }: SearchBarProps) => {
  const [isDateMenuOpen, setIsDateMenuOpen] = useState(false);
  const [dateTypeSelected, setDateTypeSelected] = useState<number>(2);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const dispatch = useDispatch();

  const selectedFromData = useSelector(
    (state: RootState) => state.date.fromDate
  );
  const selectedToData = useSelector((state: RootState) => state.date.toDate);
  const selectedDateType = useSelector(
    (state: RootState) => state.date.selectedDateType
  );

  const toggleMenu = () => setIsDateMenuOpen(!isDateMenuOpen);
  const closeMenu = () => setIsDateMenuOpen(false);

  const outsideClick = useOutsideClick(closeMenu);

  const handleFromDate = () => {
    const formattedDate = moment(fromDate).format(dateFormat);
    dispatch(fromDateData(formattedDate));
  };

  const handleToDate = () => {
    const formattedDate = moment(toDate).format(dateFormat);
    dispatch(toDateData(formattedDate));
  };

  const handleDateSelection = () => {
    if (dateTypeSelected === 2) {
      const currentDate = new Date();
      const currentDateFormatted = moment(currentDate).format(dateFormat);

      dispatch(fromDateData(currentDateFormatted));
      dispatch(toDateData(currentDateFormatted));
      setFromDate('');
      setToDate('');
    }

    if (dateTypeSelected === 1) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      const yesterdayDateFormatted = moment(currentDate).format(dateFormat);

      dispatch(toDateData(yesterdayDateFormatted));
      dispatch(fromDateData(yesterdayDateFormatted));
      setFromDate('');
      setToDate('');
    }

    if (dateTypeSelected === 3) {
      handleFromDate();
      handleToDate();
    }
    dispatch(selectedDateTypeData(dateTypeSelected));
    toggleMenu();
  };

  const handleClear = () => {
    const currentDate = new Date();
    const currentDateFormatted = moment(currentDate).format(dateFormat);

    dispatch(fromDateData(currentDateFormatted));
    dispatch(toDateData(currentDateFormatted));
    dispatch(selectedDateTypeData(2));
    toggleMenu();
  };

  useEffect(() => {
    setDateTypeSelected(selectedDateType);
    setFromDate(moment(selectedFromData).format('YYYY-MM-DD'));
    setToDate(moment(selectedToData).format('YYYY-MM-DD'));
  }, []);

  return (
    <div
      className={clsx(
        'w-[95%] h-[75px] my-3 rounded-[10px] flex flex-row items-center justify-between px-2 z-10',
        headerBackgroundColors
      )}
    >
      {type === 'insights' ? (
        <p className='text-[30px] font-semibold text-white'>Insights</p>
      ) : (
        <div></div>
        // <input
        //   className='h-[50px] w-[300px] rounded-[10px] p-2'
        //   placeholder='Search '
        // />
      )}

      <div className='flex relative' ref={outsideClick}>
        <div
          className='bg-[#fff] text-black h-[50px] w-[300px] rounded-[10px] ml-[auto] mr-0 flex items-center justify-center cursor-pointer p-2 z-10'
          onClick={toggleMenu}
        >
          {dateTypeSelected === 1 && 'Yesterday'}
          {dateTypeSelected === 2 && 'Today'}
          {dateTypeSelected === 3 &&
            selectedFromData &&
            selectedToData &&
            ` ${moment(selectedFromData).format('MMM DD, YYYY')} - ${moment(
              selectedToData
            ).format('MMM DD, YYYY')}`}

          <FaCaretDown className='ml-2' />
        </div>
        {isDateMenuOpen && (
          <div
            className={clsx(
              'absolute px-4 py-5  min-h-[50px] w-[350px] top-[50px] rounded-[12px]',
              headerBackgroundColors
            )}
          >
            <div>
              <div className='flex items-center gap-2'>
                <p className='text-white font-semibold'>Date Range</p>

                <select
                  className={clsx(
                    'border p-2 w-[200px] rounded-[3px] text-white font-semibold',
                    headerBackgroundColors
                  )}
                  value={dateTypeSelected}
                  onChange={(e) => setDateTypeSelected(Number(e.target.value))}
                >
                  <option value={1}>Yesterday</option>
                  <option value={2}>Today</option>
                  <option value={3}>Custom</option>
                </select>
              </div>

              {dateTypeSelected > 2 && (
                <div>
                  <div className='my-6 flex items-center justify-between'>
                    <input
                      type='date'
                      className={clsx(
                        'w-[140px] font-semibold p-2 border rounded-[3px] text-white',
                        headerBackgroundColors
                      )}
                      name='fromDate'
                      onChange={(e) => setFromDate(e.target.value)}
                      value={fromDate}
                    />

                    <input
                      type='date'
                      className={clsx(
                        'w-[140px] font-semibold p-2 border rounded-[3px] text-white',
                        headerBackgroundColors
                      )}
                      name='toDate'
                      onChange={(e) => setToDate(e.target.value)}
                      min={fromDate}
                      value={toDate}
                    />
                  </div>

                  <hr className='my-5' />
                </div>
              )}

              <div className='my-6 flex items-center justify-between'>
                <div
                  className='px-10 py-2 rounded-[12px] bg-white cursor-pointer font-semibold'
                  onClick={handleDateSelection}
                >
                  Apply
                </div>

                <div
                  className={clsx(
                    'px-10 py-2 rounded-[12px] cursor-pointer font-semibold border text-white',
                    headerBackgroundColors
                  )}
                  onClick={handleClear}
                >
                  Clear
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBar;
