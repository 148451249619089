import React from 'react';
import AlternateColorTable from '../../component/tables/alternateColorsTableWithSelection';
import { ColumnDef } from '@tanstack/react-table';
import { AiOutlineDownload } from 'react-icons/ai';
import { LiaTimesSolid } from 'react-icons/lia';
import { useNavigate } from 'react-router-dom';

const ViewData = () => {
  const navigate = useNavigate();
  type Transaction = {
    image: string;
    vendor: string;
    totalSales: string;
    date: string;
    status: string;
  };

  const columns = React.useMemo<ColumnDef<Transaction>[]>(
    () => [
      {
        accessorKey: 'vendor',
        id: 'vendor',
        cell: ({ row }) => {
          return (
            <div
              //   onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.vendor}
            </div>
          );
        },
      },
      {
        accessorFn: (row) => row.totalSales,
        id: 'totalSales',
        cell: ({ row }) => {
          return (
            <div
              //   onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.totalSales}
            </div>
          );
        },
      },
      {
        accessorKey: 'date',
        header: () => 'Date',
        cell: ({ row }) => {
          return (
            <div
              //   onClick={() => handleUserClick(row.original)}
              className='cursor-pointer'
            >
              {row.original.date}
            </div>
          );
        },
      },
      {
        accessorKey: 'status',
        header: () => 'status',
      },
    ],
    []
  );

  const data = [
    {
      totalSales: '1222',
      vendor: '1222',
      date: '1222',
      status: 'false',
    },
    {
      totalSales: '1222',
      vendor: '1222',
      date: '1222',
      status: 'false',
    },
    {
      totalSales: '1222',
      vendor: '1222',
      date: '1222',
      status: 'false',
    },
    {
      totalSales: '1222',
      vendor: '1222',
      date: '1222',
      status: 'false',
    },
  ];

  return (
    <div className='p-2 w-full md:w-[95%]'>
      <div className='flex justify-between'>
        <AiOutlineDownload size={30} className='cursor-pointer' />
        <LiaTimesSolid
          size={30}
          color='red'
          className='cursor-pointer'
          onClick={() => navigate(-1)}
        />
      </div>
      <div className='my-5 flex items-center justify-between'>
        <div>
          <p className='capitalize text-[23px] font-semibold'>
            HIGH VALUE TRANSACTIONS
          </p>
          <p className='text-[12px] mt-2 font-semibold'>May 14, 2024</p>
        </div>

        <div className='flex flex-col items-center'>
          <img
            className='h-20 w-20 rounded-full'
            src='https://placehold.co/400'
          />
          <p className='text-[12px] mt-1 text-[#0964AD]'>Jumanne Rashidi</p>
        </div>
      </div>
      <hr className='border-black border-1 my-2' />
      <div className='flex flex-col items-center '>
        <div className='w-[95%]'>
          <AlternateColorTable columns={columns} row={data} />
        </div>
      </div>
    </div>
  );
};

export default ViewData;
